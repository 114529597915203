export const supportedChains = (chainId) => {
  const chains = [
    {
      name: "Dogechain",
      short_name: "doge",
      chain: "DOGE",
      network: "dogechain",
      chain_id: 2000,
      network_id: 2000,
      rpc_url: "https://rpc.ankr.com/dogechain",
      image: "doge.png",
      native_currency: {
        symbol: "DOGE",
        name: "DOGE",
        decimals: "18",
        contractAddress: "",
        balance: "",
      },
      explorer: "https://explorer.dogechain.dog",
    },
    {
      name: "Ethereum Mainnet",
      short_name: "eth",
      chain: "ETH",
      network: "mainnet",
      chain_id: 1,
      network_id: 1,
      rpc_url: "https://mainnet.infura.io/v3/%API_KEY%",
      image: "weth.png",
      native_currency: {
        symbol: "ETH",
        name: "Ether",
        decimals: "18",
        contractAddress: "",
        balance: "",
      },
      explorer: "https://etherscan.io",
    },
    {
      name: "Ethereum Ropsten",
      short_name: "rop",
      chain: "ETH",
      network: "ropsten",
      chain_id: 3,
      network_id: 3,
      image: "weth.png",
      rpc_url: "https://ropsten.infura.io/v3/%API_KEY%",
      native_currency: {
        symbol: "ETH",
        name: "Ether",
        decimals: "18",
        contractAddress: "",
        balance: "",
      },
    },
    {
      name: "Ethereum Rinkeby",
      short_name: "rin",
      chain: "ETH",
      network: "rinkeby",
      chain_id: 4,
      network_id: 4,
      image: "weth.png",
      rpc_url: "https://rinkeby.infura.io/v3/%API_KEY%",
      native_currency: {
        symbol: "ETH",
        name: "Ether",
        decimals: "18",
        contractAddress: "",
        balance: "",
      },
    },
    {
      name: "Ethereum Görli",
      short_name: "gor",
      chain: "ETH",
      network: "goerli",
      chain_id: 5,
      network_id: 5,
      image: "weth.png",
      rpc_url: "https://goerli.infura.io/v3/%API_KEY%",
      native_currency: {
        symbol: "ETH",
        name: "Ether",
        decimals: "18",
        contractAddress: "",
        balance: "",
      },
    },
    {
      name: "RSK Mainnet",
      short_name: "rsk",
      chain: "RSK",
      network: "mainnet",
      chain_id: 30,
      network_id: 30,
      image: "rsk.png",
      rpc_url: "https://public-node.rsk.co",
      native_currency: {
        symbol: "RSK",
        name: "RSK",
        decimals: "18",
        contractAddress: "",
        balance: "",
      },
    },
    {
      name: "Ethereum Kovan",
      short_name: "kov",
      chain: "ETH",
      network: "kovan",
      chain_id: 42,
      network_id: 42,
      image: "weth.png",
      rpc_url: "https://kovan.infura.io/v3/%API_KEY%",
      native_currency: {
        symbol: "ETH",
        name: "Ether",
        decimals: "18",
        contractAddress: "",
        balance: "",
      },
    },
    {
      name: "Ethereum Classic Mainnet",
      short_name: "etc",
      chain: "ETC",
      network: "mainnet",
      chain_id: 61,
      network_id: 1,
      image: "etc.png",
      rpc_url: "https://ethereumclassic.network",
      native_currency: {
        symbol: "ETH",
        name: "Ether Classic",
        decimals: "18",
        contractAddress: "",
        balance: "",
      },
    },
    {
      name: "POA Network Sokol",
      short_name: "poa",
      chain: "POA",
      network: "sokol",
      chain_id: 77,
      network_id: 77,
      image: "poa.png",
      rpc_url: "https://sokol.poa.network",
      native_currency: {
        symbol: "POA",
        name: "POA",
        decimals: "18",
        contractAddress: "",
        balance: "",
      },
    },
    {
      name: "POA Network Core",
      short_name: "skl",
      chain: "POA",
      network: "core",
      chain_id: 99,
      network_id: 99,
      image: "poa.png",
      rpc_url: "https://core.poa.network",
      native_currency: {
        symbol: "POA",
        name: "POA",
        decimals: "18",
        contractAddress: "",
        balance: "",
      },
    },
    {
      name: "xDAI Chain",
      short_name: "xdai",
      chain: "POA",
      network: "dai",
      chain_id: 100,
      network_id: 100,
      image: "xdai.png",
      rpc_url: "https://dai.poa.network",
      native_currency: {
        symbol: "xDAI",
        name: "xDAI",
        decimals: "18",
        contractAddress: "",
        balance: "",
      },
    },
    {
      name: "Callisto Mainnet",
      short_name: "clo",
      chain: "callisto",
      network: "mainnet",
      chain_id: 820,
      network_id: 1,
      image: "clo.png",
      rpc_url: "https://clo-geth.0xinfra.com/",
      native_currency: {
        symbol: "CLO",
        name: "CLO",
        decimals: "18",
        contractAddress: "",
        balance: "",
      },
    },
    {
      name: "Polygon Testnet Mumbai",
      chain: "Polygon",
      network: "mumbai",
      image: "polygon.png",
      rpc_url: "https://rpc-mumbai.maticvigil.com",
      native_currency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: "18",
        contractAddress: "",
        balance: "",
      },
      short_name: "maticmum",
      chain_id: 80001,
      network_id: 80001,
      explorer: "https://mumbai.polygonscan.com",
    },
    {
      name: "Polygon Mainnet",
      chain: "Polygon",
      network: "mainnet",
      image: "polygon.png",
      rpc_url: "https://rpc-mainnet.maticvigil.com",
      native_currency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: "18",
        contractAddress: "",
        balance: "",
      },
      short_name: "MATIC",
      chain_id: 137,
      network_id: 137,
      explorer: "https://polygonscan.com",
    },
    {
      name: "Avalanche Fuji Testnet",
      chain: "AVAX",
      network: "fuji",
      image: "avax.png",
      rpc_url: "https://api.avax-test.network/ext/bc/C/rpc",
      native_currency: {
        name: "Avalanche",
        symbol: "AVAX",
        decimals: "18",
        contractAddress: "",
        balance: "",
      },
      short_name: "Fuji",
      chain_id: 43113,
      network_id: 1,
      explorer: "https://testnet.snowtrace.io",
    },
    {
      name: "Avalanche Mainnet",
      chain: "AVAX",
      network: "mainnet",
      image: "avax.png",
      rpc_url: "https://api.avax.network/ext/bc/C/rpc",
      native_currency: {
        name: "Avalanche",
        symbol: "AVAX",
        decimals: "18",
        contractAddress: "",
        balance: "",
      },
      short_name: "Avalanche",
      chain_id: 43114,
      network_id: 43114,
      explorer: "https://snowtrace.io",
    },
    {
      name: "Arbitrum One",
      chain_id: 42161,
      network_id: 42161,
      network: "mainnet",
      short_name: "arb1",
      chain: "ETH",
      image: "arbitrum.png",
      native_currency: {
        name: "Ether",
        symbol: "AETH",
        decimals: "18",
        contractAddress: "",
        balance: "",
      },
      rpc_url: "https://arb1.arbitrum.io/rpc",
      explorer: "https://arbiscan.io",
    },
    {
      name: "Optimism",
      chain: "ETH",
      chain_id: 10,
      network_id: 10,
      rpc_url: "https://mainnet.optimism.io/",
      network: "mainnet",
      image: "op.png",
      native_currency: {
        name: "Ether",
        symbol: "OETH",
        decimals: "18",
        contractAddress: "",
        balance: "",
      },
      short_name: "oeth",
      explorer: "https://optimistic.etherscan.io",
    },
    {
      name: "Binance Smart Chain",
      chain: "BNB",
      chain_id: 56,
      network_id: 56,
      rpc_url: "https://rpc.ankr.com/bsc",
      network: "mainnet",
      image: "busd.png",
      native_currency: {
        name: "BNB",
        symbol: "BNB",
        decimals: "18",
        contractAddress: "",
        balance: "",
      },
      short_name: "bsc",
      explorer: "https://bscscan.com",
    },
    {
      name: "Fantom Opera",
      chain: "FTM",
      chain_id: 250,
      network_id: 250,
      rpc_url: "https://rpc3.fantom.network",
      network: "mainnet",
      image: "ftm.png",
      native_currency: {
        name: "Fantom",
        symbol: "FTM",
        decimals: "18",
        contractAddress: "",
        balance: "",
      },
      short_name: "ftm",
      explorer: "https://ftmscan.com",
    },
    {
      name: "Cronos",
      chain: "CRO",
      chain_id: 25,
      network_id: 25,
      rpc_url: "https://evm.cronos.org",
      network: "mainnet",
      image: "cro.png",
      native_currency: {
        name: "Cronos",
        symbol: "CRO",
        decimals: "18",
        contractAddress: "",
        balance: "",
      },
      short_name: "cronos",
      explorer: "https://cronosscan.io",
    },
    {
      name: "EthereumPow",
      chain: "ETHW",
      chain_id: 10001,
      network_id: 10001,
      rpc_url: "https://mainnet.ethereumpow.org",
      network: "mainnet",
      image: "weth.png",
      native_currency: {
        name: "ETHPOW",
        symbol: "ETHW",
        decimals: "18",
        contractAddress: "",
        balance: "",
      },
      short_name: "ethw",
      explorer: "https://www.oklink.com/en/ethw",
    },
  ];

  const result = chains.filter((chain) => chain.chain_id == chainId)[0];

  if (result) {
    return result;
  } else {
    return {
      name: "Dogechain",
      short_name: "doge",
      chain: "DOGE",
      network: "dogechain",
      chain_id: 2000,
      network_id: 2000,
      rpc_url: "https://rpc.ankr.com/dogechain",
      image: "doge.png",
      native_currency: {
        symbol: "DOGE",
        name: "DOGE",
        decimals: "18",
        contractAddress: "",
        balance: "",
      },
      explorer: "https://explorer.dogechain.dog",
    };
  }
};
