<template>
  <div class="container">Hellow World</div>
</template>

<script>
// import Web3 from "web3";
export default {
  components: {},
  data() {
    return {
      rpc: "https://rpc.ankr.com/dogechain",
    };
  },
  setup() {},
  computed: {},
  methods: {},
  async mounted() {},
};
</script>
