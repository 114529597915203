<template>
  <div class="row nftrow">
    <div class="col-md-2">
      <NftCard
        image="https://skyroad-finance.web.app/collections/0xbeae0fd8ccecc76afcc137d89f2b006e8c543c84/images/6053"
        id="6053"
        name="SkyRoad"
        price="0.1"
        owner="0x0000000000000000000000000000000000000000"
      />
    </div>

    <div class="col-md-2">
      <NftCard
        image="https://skyroad-finance.web.app/collections/0xbeae0fd8ccecc76afcc137d89f2b006e8c543c84/images/6053"
        id="6053"
        name="SkyRoad"
        price="0.1"
        owner="0x0000000000000000000000000000000000000000"
      />
    </div>

    <div class="col-md-2">
      <NftCard
        image="https://skyroad-finance.web.app/collections/0xbeae0fd8ccecc76afcc137d89f2b006e8c543c84/images/6053"
        id="6053"
        name="SkyRoad"
        price="0.1"
        owner="0x0000000000000000000000000000000000000000"
      />
    </div>
  </div>
</template>

<script>
import NftCard from "./NftCard.vue";
export default {
  components: {
    NftCard,
  },
};
</script>

<style scoped>
.nftrow {
  margin-top: 20px;
  margin-bottom: 40px;
}
</style>
