import Web3 from "web3";
import useClipboard from "vue-clipboard3";

export const cutAddress = (address) => {
  if (address) {
    let first = address.substring(0, 5);
    let last = address.substring(address.length - 5);
    return `${first}...${last}`;
  }
};

export const formatBalance = (amount, decimal) => {
  if (amount < 0.1) {
    decimal = 5;
  } else {
    decimal = 2;
  }
  return Number(amount).toLocaleString("en-US", {
    maximumFractionDigits: decimal,
  });
};

export const convertToEther = (amount) => {
  const convert = Web3.utils.fromWei(amount.toString(), "ether");
  return convert;
};

export const convertEtherToWei = (value, decimals) => {
  if (decimals < 18) {
    return value * 10 ** decimals;
  } else {
    return Web3.utils.toWei(value, "ether");
  }
};

export const convertWeiToEther = (value, decimals) => {
  if (decimals < 18) {
    return value / 10 ** decimals;
  } else {
    return Web3.utils.fromWei(value.toString(), "ether");
  }
};

export const formatMoney = (input) => {
  let decimals;
  if (input < 0.1) {
    decimals = 5;
  } else {
    decimals = 2;
  }
  return Number(input).toLocaleString("en-US", {
    maximumFractionDigits: decimals,
  });
};

export const copyToClipBoard = async (textToCopy) => {
  const { toClipboard } = useClipboard();
  try {
    await toClipboard(textToCopy);
    console.log("Copied to clipboard");
  } catch (e) {
    console.error(e);
  }
};
