<template>
  <div class="card">
    <div class="card-header">
      <span class="pending"></span>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-4">
          <table class="table table-striped">
            <tbody>
              <tr>
                <th>Earn:</th>
                <td class="strong"><span class="pending"></span></td>
              </tr>
              <tr>
                <th>Deposit Fee:</th>
                <td class="strong"><span class="pending"></span></td>
              </tr>
              <tr>
                <th>APR:</th>
                <td>
                  <span class="pending"></span>
                </td>
              </tr>
              <tr>
                <th>Daily ROI:</th>
                <td>
                  <span class="pending"></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="col-md-4">
          <div>
            <div>
              <span class="userStakedPrice"></span>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  aria-label=""
                  aria-describedby="button-addon2"
                />
                <button
                  class="btn btn-success"
                  type="button"
                  id="button-addon2"
                >
                  <i class="fas fa-plus"></i>
                </button>
              </div>
            </div>
            <hr />
            <div>
              <span class="userStakedPrice"></span>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  aria-label=""
                  aria-describedby="button-addon2"
                />
                <button
                  class="btn btn-warning"
                  type="button"
                  id="button-addon2"
                >
                  <i :class="`fas fa-minus`"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="harvest-amount">
            <span class="tokenName">Earned</span>
            <span class="tokenAmount"> <span class="pending"></span></span>
          </div>

          <hr />

          <div class="harvest-section">
            <span class="tokenName"><span class="pending"></span></span>
            <span class="tokenAmount">
              <button type="button" class="disabled btn btn-primary">
                Harvest
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <small class="text-muted card-foot"
        >TVL: <span class="pending"></span> -
      </small>

      <small class="text-muted card-foot">
        Add Liquidity -
        <span class="pending"></span
      ></small>
      <small class="text-muted card-foot">
        Remove Liquidity -
        <span class="pending"></span
      ></small>
    </div>
  </div>
</template>

<style scoped>
table tbody td span {
  width: 100%;
  min-width: 40px;
  height: 20px;
  background: #eeeeee;
  display: block;
  animation: pulse-bg 1s infinite;
  border-radius: 5px;
}

.dark span.pending {
  background: #08091c;
  animation: pulse-bg-dark 1s infinite;
}

@keyframes pulse-bg-dark {
  0% {
    background-color: #08091c;
  }
  50% {
    background-color: #1b2443;
  }
  100% {
    background-color: #08091c;
  }
}
span.pending {
  width: 100%;
  min-width: 40px;
  height: 20px;
  background: #eeeeee;
  display: block;
  animation: pulse-bg 1s infinite;
  border-radius: 5px;
}

@keyframes pulse-bg {
  0% {
    background-color: #eeeeee;
  }
  50% {
    background-color: #eaeaea;
  }
  100% {
    background-color: #eeeeee;
  }
}
.card-foot {
  padding-right: 25px;
}
.approval {
  background: #f7faff;
  border-radius: 5px;
}
.approval .btn {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 55px;
}
.harvest-amount {
  display: block;
  padding-bottom: 15px;
  padding-top: 15px;
  height: 56px;
}
.harvest-amount .tokenName {
  float: left;
  font-weight: bold;
  padding-top: 10px;
  padding-left: 20px;
  font-size: 20px;
  text-transform: uppercase;
}

.harvest-amount .tokenAmount {
  float: right;
  padding-right: 20px;
  padding-top: 10px;
  font-size: 20px;
}

.harvest-section .tokenName {
  float: left;
  font-weight: bold;
  padding-left: 20px;
  font-size: 26px;
}

.stakedBackground {
  border-left: green solid 6px;
}

.harvest-section .tokenAmount {
  float: right;
  padding-right: 20px;
}

.aprUsd {
  float: right;
  font-size: 10px;
  color: green;
}

.aprPercent {
  float: left;
}

.tokenTitle {
  text-transform: uppercase;
}

.tokenBadges {
  float: right;
  margin-right: 10px;
}

.form-control {
  font-size: 12px;
}

.strong {
  font-weight: bold;
}

.userStaked {
  float: left;
  font-size: 12px;
  color: #333333;
}

.userStakedPrice {
  float: right;
  font-size: 12px;
  color: #333333;
}

.table {
  width: 100%;
}

.table tr th {
  min-width: 100px;
}

h4.card-title {
  color: #333333;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
</style>
