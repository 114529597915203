<template>
  <div class="container">
    <div class="topAccount">
      <div class="identicon">
        <VueBlockies
          :seed="web3.coinbase"
          :size="24"
          :scale="8"
          color="#09e"
          bgcolor="#f3f"
          spotColor="#000"
        />
      </div>
      <div class="account">
        <div class="accountName">
          <img src="@/assets/tokens/doge.png" alt="doge" />
          <span class="act">{{ cutAddress(web3.coinbase) }}</span>
          <span class="balance"
            >{{ formatBalance(convertToDoge(web3.balance), 2) }} DOGE</span
          >
        </div>
      </div>
    </div>
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button
          class="nav-link active"
          id="nav-my-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-my"
          type="button"
          role="tab"
          aria-controls="nav-my"
          aria-selected="true"
        >
          My NFTs
        </button>
        <button
          class="nav-link"
          id="nav-listed-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-listed"
          type="button"
          role="tab"
          aria-controls="nav-listed"
          aria-selected="false"
        >
          Listed NFTs
        </button>
        <button
          class="nav-link"
          id="nav-staked-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-staked"
          type="button"
          role="tab"
          aria-controls="nav-staked"
          aria-selected="false"
        >
          Staked NFTs
        </button>
        <button
          class="nav-link"
          id="nav-favorite-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-favorite"
          type="button"
          role="tab"
          aria-controls="nav-favorite"
          aria-selected="false"
        >
          Favorites
        </button>
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div
        class="tab-pane fade show active"
        id="nav-my"
        role="tabpanel"
        aria-labelledby="nav-my-tab"
      >
        <MyNfts />
      </div>
      <div
        class="tab-pane fade"
        id="nav-listed"
        role="tabpanel"
        aria-labelledby="nav-listed-tab"
      >
        Coming soon...
      </div>
      <div
        class="tab-pane fade"
        id="nav-staked"
        role="tabpanel"
        aria-labelledby="nav-staked-tab"
      >
        Coming Soon...
      </div>

      <div
        class="tab-pane fade"
        id="nav-favorite"
        role="tabpanel"
        aria-labelledby="nav-favorite-tab"
      >
        Coming soon...
      </div>
    </div>
  </div>
</template>

<script>
import VueBlockies from "vue-blockies";
import { store } from "../../store";
import MyNfts from "../../components/marketplace/MyNfts.vue";
import Web3 from "web3";
export default {
  components: {
    VueBlockies,
    MyNfts,
  },
  computed: {
    web3() {
      return store.state.web3;
    },
  },
  methods: {
    cutAddress(address) {
      if (address) {
        let first = address.substring(0, 6);
        let last = address.substring(address.length - 6);
        return `${first}...${last}`;
      }
    },
    convertToDoge: (amount) => {
      const convert = Web3.utils.fromWei(amount.toString(), "ether");
      console.log(`convertToDoge: ${convert}`);
      return convert;
    },
    formatBalance: (amount, decimal) => {
      if (amount < 0.1) {
        decimal = 5;
      } else {
        decimal = 2;
      }
      return Number(amount).toLocaleString("en-US", {
        maximumFractionDigits: decimal,
      });
    },
  },
};
</script>

<style scoped>
.topAccount {
  margin-top: 25px;
  margin-bottom: 25px;
}

.nav-tabs {
  border-color: #273463;
}

.nav-tabs .nav-link {
  background-color: #1b2443;
  color: #fff;
  margin-right: 7px;
}

.nav-tabs .nav-link.active {
  background-color: #8ea6ff;
  color: #000;
}

.nav-tabs .nav-link:hover {
  border-color: #4055a2;
}

.identicon img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: solid 5px #fff;
}

.identicon {
  margin-bottom: 20px;
}

.accountName img {
  width: 20px;
  height: 20px;
}

.accountName span {
  font-weight: 400;
  font-size: 0.9rem;
  margin-left: 10px;
}
</style>
