<template>
  <div class="nftcard">
    <div class="nftImage">
      <img :src="image" :alt="id" />
    </div>
    <div class="nftInfo">
      <div class="nftName">Name: {{ name }}</div>
      <div class="nftId">ID: {{ id }}</div>
      <div class="nftPrice">Price: {{ price }} DOGE</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: String,
    id: Number,
    price: Number,
    name: String,
    url: String,
  },
};
</script>

<style scoped>
.nftcard {
  border-radius: 8px;
  background-color: #1b2443;
  border: 1px solid #57718a;
}

.nftInfo {
  padding: 10px;
  font-size: 0.8rem;
  font-weight: 300;
}

.nftName,
.nftId,
.nftPrice {
  color: #fff;
  margin-bottom: 12px;
}

.nftImage img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
</style>
