<template>
  <div class="topSectionFarm">
    <div class="row">
      <div class="col-md-12">
        <span class="alert alert-warning topwarning"
          >Notice: Max supply of 50,000 tokens reached, stake your DOGEPUP-WDOGE
          LP on the <router-link to="/">vaults</router-link>.</span
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="top">{{ tokenData.symbol }} Price</div>
              <div class="bottom">${{ formatBalance(tokenData.price, 2) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class="top">{{ tokenData.symbol }} Balance</div>
            <div class="bottom">
              <div>
                {{ formatBalance(tokenData.balance, 5) }}
                <span class="green"
                  >(${{
                    formatBalance(tokenData.balance * tokenData.price, 2)
                  }})</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class="top">{{ tokenData.symbol }} Pending</div>
            <div class="bottom">
              <div>
                {{ formatBalance(totalPending, 5) }}
                <span class="green"
                  >(${{
                    formatBalance(totalPending * tokenData.price, 2)
                  }})</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class="top">Staked Value</div>
            <div class="bottom">
              <div>${{ formatBalance(totalStakedAmount, 2) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card minHeight">
          <div class="card-body">
            <table class="table">
              <tr>
                <th>Symbol:</th>
                <td>
                  {{ tokenData.symbol }}
                  <span v-if="tokenData"
                    ><img
                      class="metamask"
                      @click="addToMetamask(tokenData)"
                      src="@/assets/metamask.png"
                  /></span>
                </td>
              </tr>
              <tr>
                <th>Token Address:</th>
                <td>
                  {{ shortenAddress(tokenData.address) }}
                  <a href="" @click.prevent="copyToClipBoard(tokenData.address)"
                    ><font-awesome-icon icon="fa-solid fa-copy"
                  /></a>
                </td>
              </tr>
              <tr>
                <th>Masterchef Address:</th>
                <td>
                  {{ shortenAddress(mc) }}
                  <a href="" @click.prevent="copyToClipBoard(mc)"
                    ><font-awesome-icon icon="fa-solid fa-copy"
                  /></a>
                </td>
              </tr>
              <tr>
                <th>Max Supply:</th>
                <td>50,000</td>
              </tr>
              <tr>
                <th>Circulating Supply:</th>
                <td>{{ tokenData.total_supply - tokenData.burned }}</td>
              </tr>
              <tr>
                <th>Burned:</th>
                <td>{{ tokenData.burned }}</td>
              </tr>
              <tr>
                <th>MarketCap:</th>
                <td>
                  ${{
                    formatBalance(
                      tokenData.price *
                        (tokenData.total_supply - tokenData.burned),
                      2
                    )
                  }}
                </td>
              </tr>
              <tr>
                <th>Emissions:</th>
                <td>0.02 per second</td>
              </tr>
              <tr>
                <th>Total Value Locked:</th>
                <td>${{ formatBalance(tvl) }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <a
          data-height="300"
          class="twitter-timeline"
          data-theme="dark"
          href="https://twitter.com/PolyPup1"
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
import { createToast } from "mosha-vue-toastify";
export default {
  props: {
    tokenData: Object,
    totalPending: Number,
    totalStakedAmount: Number,
    tvl: Number,
  },
  data() {
    return {
      mc: "0xc6dEcf90D8171B0E17f367C9f2fA4560C73845da",
    };
  },
  mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute("src", "//platform.twitter.com/widgets.js");
    plugin.async = true;
    document.head.appendChild(plugin);
  },
  methods: {
    createDate(date) {
      date = new Date(date);
      return (
        date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
      );
    },
    removeSpace: (data) => {
      return data.replace(" ", "").toLowerCase();
    },

    removeItemAll(arr, value) {
      var i = 0;
      while (i < arr.length) {
        if (arr[i] === value) {
          arr.splice(i, 1);
        } else {
          ++i;
        }
      }
      return arr;
    },
    copyToClipBoard(textToCopy) {
      const tmpTextField = document.createElement("textarea");
      tmpTextField.textContent = textToCopy;
      tmpTextField.setAttribute("style", "position:absolute; right:200%;");
      document.body.appendChild(tmpTextField);
      tmpTextField.select();
      tmpTextField.setSelectionRange(0, 99999); /*For mobile devices*/
      document.execCommand("copy");
      tmpTextField.remove();
    },
    addToMetamask: async (tokenData) => {
      if (window.ethereum) {
        try {
          if (tokenData) {
            const tokenAddress = tokenData.address;
            const tokenSymbol = tokenData.symbol;
            const tokenDecimals = tokenData.decimals;
            const tokenImage = "";

            // wasAdded is a boolean. Like any RPC method, an error may be thrown.
            const wasAdded = await window.ethereum.request({
              method: "wallet_watchAsset",
              params: {
                type: "ERC20", // Initially only supports ERC20, but eventually more!
                options: {
                  address: tokenAddress, // The address that the token is at.
                  symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                  decimals: tokenDecimals, // The number of decimals in the token
                  image: tokenImage, // A string url of the token logo
                },
              },
            });

            if (wasAdded) {
              createToast(`Token added success`, {
                toastBackgroundColor: "green",
                transition: "bounce",
                type: "success",
              });
            } else {
              createToast(`Token not added`, {
                toastBackgroundColor: "red",
                transition: "bounce",
                type: "fail",
              });
            }
          } else {
            console.log("notokendata");
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        console.log("sorry, no metamask detected...");
      }
    },
    shortenAddress: (address) => {
      return `${address.substring(0, 12)}...`;
    },
    formatBalance: (amount, decimal) => {
      if (amount < 0.1) {
        decimal = 5;
      } else {
        decimal = 2;
      }
      return Number(amount).toLocaleString("en-US", {
        maximumFractionDigits: decimal,
      });
    },
  },
  computed: {},
};
</script>

<style scoped>
a.leastrisk,
a.lowrisk,
a.somerisk,
a.mediumrisk,
a.highrisk,
a.noteligible {
  text-decoration: none;
  margin-left: 15px;
}
a.leastrisk:hover,
a.lowrisk:hover,
a.somerisk:hover,
a.mediumrisk:hover,
a.highrisk:hover,
a.noteligible:hover {
  text-decoration: none;
  color: #fff;
}

.leastrisk {
  opacity: 1;
  color: palegreen;
  border: 1px solid green;
  background-color: green;
}

.somerisk {
  opacity: 1;
  background: #3f546a;
  border: 1px solid #5c7894;
}

.lowrisk {
  opacity: 1;
  background: #215489;
  border: 1px solid #4b81b9;
}
.highrisk {
  opacity: 1;
  background: #441215;
  border: 1px solid #961413;
}
.mediumrisk {
  opacity: 1;
  background: #835822;
  border: 1px solid #cc7d08;
}

.favorite_star {
  color: #ffc107;
  padding-left: 6px;
  cursor: pointer;
}
.pad-top {
  margin-top: 30px;
}
.fixedHeight {
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
}

.minHeight {
  min-height: 300px;
}

.dark .green {
  color: #42ff75;
}

.dark .divider {
  border-bottom: 1px solid #57718a;
}
.divider {
  margin-bottom: 25px;
  border-bottom: 1px solid #ccc;
}
.last {
  border-bottom: none;
}
.top-address {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 22px;
  margin-top: 15px;
}
.refresh {
  position: relative;
  top: 0;
  left: 91%;
  font-size: 13px;
  font-weight: normal;
}
.card {
  margin-bottom: 20px;
}

.topSectionFarm {
  margin-top: 25px;
}

.topwarning {
  display: block;
  margin-bottom: 25px;
  text-align: center;
  font-size: 0.875rem;
}

.dark .top {
  color: #c9e5ff;
}

.dark .bottom {
  color: #fff;
}

.top {
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.5px;
  padding-bottom: 10px;
  padding-top: 5px;
  color: rgb(100, 100, 100);
}

.bottom {
  text-align: center;
  font-size: 24px;
  padding-bottom: 12px;
  color: rgb(25, 25, 25);
}
.green {
  color: green;
}

.bottom .green {
  font-size: 11px;
}

.harvest {
  text-align: center;
  margin-bottom: 25px;
}
.priceBottom {
  font-size: 18px;
  font-weight: lighter;
}

.socials {
  height: 24px;
  color: green;
  padding-left: 8px;
}

.socials:hover {
  color: blue;
}

.metamask {
  cursor: pointer;
}
tr th,
tr td {
  font-weight: 200;
  min-width: 100px;
  font-size: 1.075rem;
}

table tr {
  margin-bottom: 6px;
}

.started {
  background-color: rgb(0, 100, 35);
  color: #fff;
  border-radius: 5px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.xs {
  font-size: 0.775rem;
}

img.metamask {
  height: 22px;
}
</style>
