<template>
  <div class="sidebar">
    <div class="sidebarSection">
      <a
        href="#"
        class="sidebarLink"
        :class="filterAll && filterParent ? 'sidebarLinkActive' : ''"
        v-on:click.prevent="filter('all')"
        >All Pools</a
      >
    </div>
    <div class="sidebarSection">
      <a
        href="#"
        class="sidebarLink"
        :class="filterMy && filterParent ? 'sidebarLinkActive' : ''"
        v-on:click.prevent="filter('my')"
        >My Pools</a
      >
    </div>
    <div class="sidebarSection">
      <span class="badge bg-secondary sidebar_badge">
        <img src="@/assets/tokens/doge.png" class="sidebar_logo" />
        {{ formatBalance(doge, 6) }}
      </span>
    </div>

    <div class="sidebarSection">
      <span class="badge bg-secondary sidebar_badge">
        <img src="@/assets/tokens/dogepup.png" class="sidebar_logo" />
        <a
          href="https://dexscreener.com/dogechain/0x1A6089545284d47E7294b7452744208Db317fe81"
          target="_blank"
          class="sidebarLinkSquare"
          >{{ formatBalance(dogepup, 6) }}
          <font-awesome-icon icon="fa-solid fa-square-arrow-up-right"
        /></a>
      </span>
    </div>

    <div class="sidebarSection">
      <a
        class="btn btn-success sidebarBtn"
        href="https://app.yodeswap.dog/exchange/swap?outputCurrency=0x1b15b9446b9f632a78396a1680DAaE17f74Ce8d9"
        target="_blank"
        >BUY DOGEPUP <font-awesome-icon icon="fa-solid fa-rocket"
      /></a>
    </div>
    <div class="sidebarSection sidebar_socials">
      <div class="row">
        <div class="col">
          <a target="_blank" href="https://t.me/PolyPupFarm"
            ><font-awesome-icon icon="fa-brands fa-telegram"
          /></a>
        </div>
        <div class="col">
          <a target="_blank" href="https://discord.gg/5y6CF87jfC"
            ><font-awesome-icon icon="fa-brands fa-discord"
          /></a>
        </div>
        <div class="col">
          <a target="_blank" href="https://twitter.com/PolyPup1"
            ><font-awesome-icon icon="fa-brands fa-twitter"
          /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["updateFilter"],
  props: {
    dogepup: Number,
    doge: Number,
    filterParent: Boolean,
  },
  data() {
    return {
      filterAll: false,
      filterMy: false,
    };
  },
  methods: {
    filter(type) {
      this.$emit("updateFilter", type);

      if (type == "all") {
        this.filterAll = !this.filterParent ? false : true;
        this.filterMy = false;
      }

      if (type == "my") {
        this.filterAll = false;
        this.filterMy = true;
      }
    },
    formatBalance: (amount, decimal) => {
      return Number(amount).toLocaleString("en-US", {
        maximumFractionDigits: decimal,
      });
    },
  },
};
</script>

<style scoped>
.sidebar {
  margin-top: 20px;
}

.sidebarSection {
  margin-bottom: 25px;
}

.sidebar_logo {
  height: 25px;
  margin-top: -3px;
}

.sidebarLink {
  padding: 7px;
  width: 75%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 0.875rem;
  color: #acacac;
  text-decoration: none;
}

.sidebarLink:hover {
  background-color: #1b2443;
  border-radius: 5px;
}

.sidebarLinkActive {
  background-color: #1b2443;
  border-radius: 5px;
  color: #efefef;
}

.sidebar_badge {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  padding-top: 6px;
  padding-bottom: 6px;
}

.sidebarBtn {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  font-size: 0.875rem;
}

.sidebar_socials {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
}

.sidebar_socials a {
  color: #acacac;
  text-decoration: none;
}

.sidebar_socials a:hover {
  color: #efefef;
}

a.sidebarLinkSquare {
  color: #efefef;
  text-decoration: none;
}
</style>
