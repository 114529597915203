import { defineStore } from "pinia";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import { supportedChains } from "../util/chains";
import Vaults from "../abi/vaults.json";
import ERC20 from "../abi/ERC20.json";
import poolVault from "../abi/poolVault.json";

export const useStore = defineStore("wallet", {
  state: () => {
    return {
      account: null,
      chainId: null,
      chains: [2000],
      balance: null,
      image: null,
      loading: false,
      error: null,
      wallet: null,
      walletProvider: null,
      filterType: null,
      filterSecondary: null,
      filterThird: null,
      filterCount: 0,
      userVaults: [],
      userTVL: [],
      poolTVL: [],
      tvl: 0,
      total_staked: 0,
      dogepup: 0,
      doge: 0,
    };
  },
  persist: {
    storage: localStorage,
    paths: [
      "account",
      "chainId",
      "balance",
      "image",
      "wallet",
      "chains",
      "filterType",
      "filterSecondary",
      "filterThird",
      "userVaults",
      "filterCount",
      "dogepup",
      "doge",
      "userTVL",
      "poolTVL",
      "tvl",
      "total_staked",
    ],
    afterRestore: (ctx) => {
      if (ctx.store.account) {
        console.log("trying to restore wallet");
        if (ctx.store.wallet == "metamask") {
          console.log("trying metamask");
          ctx.store.walletProvider = window.ethereum;
        } else if (ctx.store.wallet == "walletconnect") {
          console.log("trying walletconnect");
          ctx.store.walletProvider = new WalletConnectProvider({
            rpc: {
              rpc: {
                1: "https://eth-mainnet.public.blastapi.io",
                2000: "https://rpc.ankr.com/dogechain",
                10001: "https://mainnet.ethereumpow.org",
              },
            },
          });
        }
      }
    },
  },
  getters: {
    ethereum() {
      return window.ethereum;
    },
    currentChain() {
      return this.chainId;
    },
    provider() {
      return new Web3(window.ethereum);
    },
    isConnected: (state) => (state.account ? true : false),
    isWrongNetwork: (state) =>
      state.chainId ? parseInt(state.chainId) !== 2000 : true,
  },
  actions: {
    async initialize() {
      console.log("initializing wallet");
      if (window.ethereum) {
        const chainId = await window.ethereum.request({
          method: "eth_chainId",
        });
        this.chainId = chainId;
        console.log("chainId", chainId);

        this.ethereum.on("chainChanged", async () => {
          await this.checkData();
        });
        this.ethereum.on("accountsChanged", async () => {
          await this.checkData();
        });
        this.ethereum.on("disconnect", async () => {
          await this.disconnect();
        });
      }
    },
    async connectWallet(network) {
      if (network == "metamask") {
        await this.useMetamask();
      }
      if (network == "walletconnect") {
        await this.useWalletConnect();
      }
    },
    async useMetamask() {
      console.log("trying to connect metamask");
      if (window.ethereum && window.ethereum.isMetaMask) {
        this.walletProvider = window.ethereum;
        const accounts = await this.ethereum.request({
          method: "eth_requestAccounts",
        });
        const chainId = await this.ethereum.request({ method: "eth_chainId" });
        this.account = accounts[0];
        this.chainId = parseInt(chainId);
        const chaindata = supportedChains(parseInt(this.chainId));
        this.image = chaindata ? chaindata.image : null;
        this.balance = await this.provider.eth.getBalance(this.account);
        this.wallet = "metamask";
        if (!this.chains.includes(parseInt(this.chainId))) {
          this.chains.push(parseInt(this.chainId));
        }
      }
    },
    async useWalletConnect() {
      console.log("using wallet connect");
      const provider = new WalletConnectProvider({
        rpc: {
          1: "https://eth-mainnet.public.blastapi.io",
          2000: "https://rpc.ankr.com/dogechain",
          10001: "https://mainnet.ethereumpow.org",
        },
      });
      await provider.enable();
      this.walletProvider = provider;
      const web3 = new Web3(provider);
      const accounts = await web3.eth.getAccounts();
      const chainId = await web3.eth.getChainId();
      this.account = accounts[0];
      this.chainId = chainId;
      const chaindata = supportedChains(parseInt(chainId));
      this.image = chaindata ? chaindata.image : null;
      this.balance = await web3.eth.getBalance(this.account);
      this.wallet = "walletconnect";
      if (!this.chains.includes(parseInt(this.chainId))) {
        this.chains.push(parseInt(this.chainId));
      }
    },
    async checkData() {
      this.account = await this.provider.eth.getCoinbase();
      this.chainId = await this.provider.eth.net.getId();
      this.balance = await this.provider.eth.getBalance(this.account);
      const chaindata = supportedChains(parseInt(this.chainId));
      this.image = chaindata ? chaindata.image : null;
    },
    async switchNetwork(chainId) {
      const chaindata = supportedChains(parseInt(chainId));
      console.log(`hex`, chaindata.chain_id.toString(16));
      console.log(chaindata);
      try {
        await this.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3.utils.toHex(chainId) }],
        });
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          this.ethereum
            .request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: `0x${chaindata.chain_id.toString(16)}`,
                  chainName: chaindata.name,
                  nativeCurrency: {
                    name: chaindata.native_currency.name,
                    symbol: chaindata.native_currency.symbol,
                    decimals: 18,
                  },
                  rpcUrls: [chaindata.rpc_url],
                  blockExplorerUrls: [chaindata.explorer],
                },
              ],
            })
            .catch((error) => {
              console.log(error);
            });

          this.checkData();
        }
      }
    },
    async approveContract(tokenContract, strategyContract) {
      try {
        return new Promise((resolve) => {
          const amount =
            "115792089237316195423570985008687907853269984665640564039457584007913129639935";
          let approve = new this.provider.eth.Contract(ERC20, tokenContract);
          approve.methods
            .approve(strategyContract, amount)
            .send({
              from: this.account,
            })
            .then(async (tx) => {
              resolve(tx);
            })
            .catch((e) => {
              resolve(e);
            });
        });
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async deposit(vaultContract, amount) {
      try {
        return new Promise((resolve) => {
          const contract = new this.provider.eth.Contract(
            poolVault,
            vaultContract
          );

          contract.methods
            .deposit(amount.toString())
            .send({
              from: this.account,
            })
            .then((tx) => {
              resolve(tx);
            })
            .catch((e) => {
              resolve(e);
            });
        });
      } catch (e) {
        console.log(e);
      }
    },
    async withdrawAll(vaultContract) {
      try {
        return new Promise((resolve) => {
          const contract = new this.provider.eth.Contract(
            poolVault,
            vaultContract
          );
          contract.methods
            .withdrawAll()
            .send({
              from: this.account,
            })
            .then((tx) => {
              resolve(tx);
            })
            .catch((e) => {
              resolve(e);
            });
        });
      } catch (e) {
        console.log(e);
        return e;
      }
    },
    async harvest(vaultContract) {
      try {
        return new Promise((resolve) => {
          const contract = new this.provider.eth.Contract(
            poolVault,
            vaultContract
          );

          contract.methods
            .harvest()
            .send({
              from: this.account,
            })
            .then((tx) => {
              resolve(tx);
            })
            .catch((e) => {
              resolve(e);
            });
        });
      } catch (e) {
        console.log(e);
      }
    },
    async addChain(chain) {
      const index = this.chains.indexOf(chain);
      if (index == -1) {
        this.chains.push(chain);
      }
    },
    async removeChain(chain) {
      const index = this.chains.indexOf(chain);
      if (index > -1) {
        this.chains.splice(index, 1);
      }
    },
    async updateFilterType(type) {
      this.filterType = type;
    },
    async updateFilterSecondary(type) {
      this.filterSecondary = type;
    },
    async updateFilterThird(type) {
      this.filterThird = type;
    },
    async clearFilters() {
      this.filterType = null;
      this.filterSecondary = null;
      this.filterThird = null;
      this.filterCount = 0;
    },
    updateFilterCount(count) {
      this.filterCount = count;
    },
    updateDogePup(dogepup) {
      this.dogepup = dogepup;
    },
    updateDoge(doge) {
      this.doge = doge;
    },
    async disconnect() {
      this.account = null;
      this.chainId = null;
      this.chains = [2000];
      this.balance = null;
      this.image = null;
      this.wallet = null;
      this.walletProvider = window.ethereum;
      this.filterType = null;
      this.filterSecondary = null;
      this.filterThird = null;
      this.userVaults = [];
      this.userTVL = [];
      this.poolTVL = [];
      this.tvl = 0;
      this.total_staked = 0;
    },
    updateUserVaults(id, action) {
      if (action == "add") {
        if (!this.userVaults.includes(id)) {
          this.userVaults.push(id);
        }
      } else {
        if (this.userVaults.includes(id)) {
          for (let i = 0; i < this.userVaults.length; i++) {
            if (this.userVaults[i] === id) {
              this.userVaults.splice(i, 1);
            }
          }
        }
      }
    },
    updateUserTVL(id, amount) {
      let data = [{ id: id, amount: amount }];

      let total = 0;
      let found = false;
      if (this.userTVL.length > 0) {
        for (let i = 0; i < this.userTVL.length; i++) {
          if (this.userTVL[i].id == data[0].id) {
            found = true;
            this.userTVL = Array.from(
              [...this.userTVL, ...data]
                .reduce((m, o) => m.set(o.id, o), new Map())
                .values()
            );

            break;
          }
        }
        if (!found) {
          this.userTVL.push(data[0]);
        }
      } else {
        this.userTVL.push(data[0]);
      }

      for (let p = 0; p < this.userTVL.length; p++) {
        total += parseFloat(this.userTVL[p].amount);
      }

      this.total_staked = total;
    },
    updateTVL(id, amount, apy) {
      let data = [{ id: id, amount: amount }];

      let total = 0;
      let found = false;
      if (this.poolTVL.length > 0) {
        for (let i = 0; i < this.poolTVL.length; i++) {
          if (this.poolTVL[i].id == data[0].id) {
            found = true;
            this.poolTVL = Array.from(
              [...this.poolTVL, ...data]
                .reduce((m, o) => m.set(o.id, o), new Map())
                .values()
            );

            break;
          }
        }
        if (!found) {
          this.poolTVL.push(data[0]);
        }
      } else {
        this.poolTVL.push(data[0]);
      }

      if (Vaults.pools[id - 1].tvl == 0) {
        Vaults.pools[id - 1].tvl = amount;
      }

      if (Vaults.pools[id - 1].apy == 0) {
        Vaults.pools[id - 1].apy = Math.ceil(apy);
      }

      for (let p = 0; p < this.poolTVL.length; p++) {
        total += parseFloat(this.poolTVL[p].amount);
      }

      this.tvl = total;
    },
  },
});
