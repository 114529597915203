<template>
  <h1>Oops, it looks like the page you're looking for doesn't exist.</h1>
</template>

<style scoped>
h1 {
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin-top: 3rem;
}
</style>
