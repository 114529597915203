<template>
  <div class="container">
    <div id="dexscreener-embed">
      <iframe
        src="https://dexscreener.com/dogechain/0x1A6089545284d47E7294b7452744208Db317fe81?embed=1&theme=dark"
      ></iframe>
    </div>
  </div>
</template>

<style>
#dexscreener-embed {
  position: relative;
  width: 100%;
  padding-bottom: 125%;
  margin-top: 30px;
}
@media (min-width: 1400px) {
  #dexscreener-embed {
    padding-bottom: 65%;
  }
}
#dexscreener-embed iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0;
}
</style>
