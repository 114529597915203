<template>
  <div class="row topSectionVaults">
    <div class="statSectionOuter">
      <div class="statSectionLeft">
        <div class="d-flex justify-content-start networkSelect">
          <div
            class="p-2 bd-highlight tooltips"
            :class="chains.includes(2000) ? 'isactive' : 'notactive'"
          >
            <img class="chainImage" src="@/assets/tokens/dc.png" />
            <div class="bottom">
              <p>Dogechain</p>
              <i></i>
            </div>
          </div>
          <div
            class="p-2 bd-highlight tooltips"
            :class="chains.includes(10001) ? 'isactive' : 'notactive'"
          >
            <img class="chainImage" src="@/assets/tokens/weth.png" />
            <div class="bottom">
              <p>
                EthereumPow<br />
                Coming soon!
              </p>
              <i></i>
            </div>
          </div>
          <div
            class="p-2 bd-highlight tooltips"
            :class="chains.includes(10) ? 'isactive' : 'notactive'"
          >
            <img class="chainImage" src="@/assets/tokens/op.png" />
            <div class="bottom">
              <p>Optimism<br />Coming soon!</p>
              <i></i>
            </div>
          </div>
          <div
            class="p-2 bd-highlight tooltips"
            :class="chains.includes(42161) ? 'isactive' : 'notactive'"
          >
            <img class="chainImage" src="@/assets/tokens/arbitrum.png" />
            <div class="bottom">
              <p>Arbitrum<br />Coming soon!</p>
              <i></i>
            </div>
          </div>
          <div
            class="p-2 bd-highlight tooltips"
            :class="chains.includes(137) ? 'isactive' : 'notactive'"
          >
            <img class="chainImage" src="@/assets/tokens/polygon.png" />
            <div class="bottom">
              <p>Polygon<br />Coming soon!</p>
              <i></i>
            </div>
          </div>
          <div
            class="p-2 bd-highlight tooltips"
            :class="chains.includes(43113) ? 'isactive' : 'notactive'"
          >
            <img class="chainImage" src="@/assets/tokens/avax.png" />
            <div class="bottom">
              <p>Avalanche<br />Coming soon!</p>
              <i></i>
            </div>
          </div>
          <div
            class="p-2 bd-highlight tooltips"
            :class="chains.includes(250) ? 'isactive' : 'notactive'"
          >
            <img class="chainImage" src="@/assets/tokens/ftm.png" />
            <div class="bottom">
              <p>Fantom<br />Coming soon!</p>
              <i></i>
            </div>
          </div>
        </div>
      </div>
      <div class="statSectionRight">
        <div class="statSectionTitle statsEnd">Platform</div>
        <div class="statSectionPortfolio">
          <div class="statSectionTitleItem">
            <div class="statSection">
              <div class="statSectionAbove">Staked</div>
              <div class="statSectionBelow">
                ${{ formatBalance(total_staked, 2) }}
              </div>
            </div>
            <div class="statSection">
              <div class="statSectionAbove">TVL</div>
              <div class="statSectionBelow">${{ formatBalance(tvl, 2) }}</div>
            </div>
            <div class="statSection">
              <div class="statSectionAbove">Vaults</div>
              <div class="statSectionBelow">{{ formatBalance(active, 2) }}</div>
            </div>
            <div class="statSection statSectionEnd">
              <div class="statSectionAbove">Burned</div>
              <div class="statSectionBelow">{{ formatBalance(burned, 2) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="filterSection">
      <div class="filterGroup filterGroupFirst">
        <div
          class="filterItem"
          :class="!filterType ? 'filterActive' : ''"
          @click="store.updateFilterType(null)"
        >
          All Vaults
        </div>
        <div
          class="filterItem"
          :class="filterType == 'my' ? 'filterActive' : ''"
          @click="store.updateFilterType('my')"
        >
          My Vaults
        </div>
        <div
          class="filterItem"
          :class="filterType == 'retired' ? 'filterActive' : ''"
          @click="store.updateFilterType('retired')"
        >
          Retired
        </div>
      </div>
      <div class="filterGroup">
        <div
          class="filterItem"
          :class="filterSecondary == 'featured' ? 'filterActive' : ''"
          @click="store.updateFilterSecondary('featured')"
        >
          Featured
        </div>
        <div
          class="filterItem"
          :class="filterSecondary == 'stable' ? 'filterActive' : ''"
          @click="store.updateFilterSecondary('stable')"
        >
          Stablecoins
        </div>
        <div
          class="filterItem"
          :class="filterSecondary == 'new' ? 'filterActive' : ''"
          @click="store.updateFilterSecondary('new')"
        >
          New
        </div>
      </div>
      <div class="filterGroup">
        <div
          class="filterItem"
          :class="filterThird == 'single' ? 'filterActive' : ''"
          @click="store.updateFilterThird('single')"
        >
          Single Asset
        </div>
        <div
          class="filterItem"
          :class="filterThird == 'lp' ? 'filterActive' : ''"
          @click="store.updateFilterThird('lp')"
        >
          LPs
        </div>
      </div>
      <div class="filterGroup">
        <div class="filterItem" @click="store.clearFilters()">
          <span class="filterCount" v-if="filterCount > 0">{{
            filterCount
          }}</span>
          Clear Filters
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";
import { useStore } from "../../store";
export default {
  emits: ["updateFilter", "updateDex", "updateFilterType"],
  props: {
    burned: Number,
    active: Number,
  },
  setup() {
    const store = useStore();
    const {
      chains,
      chainId,
      filterType,
      filterSecondary,
      filterThird,
      filterCount,
      total_staked,
      tvl,
    } = storeToRefs(store);
    return {
      store,
      chains,
      chainId,
      filterType,
      filterSecondary,
      filterThird,
      filterCount,
      total_staked,
      tvl,
    };
  },
  watch: {
    chainId() {
      console.log("chainId", this.chainId);
      //this.activeChain = this.chainId;
    },
    chains() {
      console.log(this.chains);
      //this.activeChain = this.chainId;
    },
  },
  data() {
    return {
      searchTerm: "",
      dexes: null,
      filter: null,
      activeChain: this.chainId || 2000,
    };
  },
  computed: {},
  methods: {
    updateNetworkSelector(chain) {
      if (this.chains.includes(chain)) {
        this.store.removeChain(chain);
      } else {
        this.chains.push(chain);
      }
    },
    onChangeDexes(event) {
      this.searchTerm = "";
      this.filter = null;
      this.$emit("updateDex", event.target.value);
    },
    onChangeFilter(event) {
      this.searchTerm = "";
      this.dexes = null;
      this.filter = event.target.value;
      this.$emit("updateFilterType", event.target.value);
    },
    formatBalance: (amount, decimal) => {
      return Number(amount).toLocaleString("en-US", {
        maximumFractionDigits: decimal,
      });
    },
  },
};
</script>

<style scoped>
.filterCount {
  width: 24px;
  height: 24px;
  flex-grow: 0;
  align-items: center;
  flex-shrink: 0;
  margin-right: 8px;
  align-items: center;
  justify-content: center;
  background-color: red;
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
}
.statSectionLeft {
  display: flex;
}
.statSection {
  padding-top: 0;
  margin-right: 32px;
  padding-bottom: 0;
  text-align: right;
}
.statSectionEnd {
  margin-right: 0px;
}
.statSectionBelow {
  color: #f5f5ff;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}
.statSectionAbove {
  color: #999cb3;
  display: inline-flex;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.statSectionTitleItem {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.statSectionItem {
  padding-top: 0;
  margin-right: 32px;
  padding-bottom: 0;
}
.statSectionPorfolio {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}
.statsEnd {
  justify-content: flex-end;
}

.statsStart {
  justify-content: flex-start;
}
.statSectionTitle {
  color: #d0d0da;
  display: flex;
  font-size: 21px;
  align-items: center;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 12px;
}
.statSectionOuter {
  grid-template-columns: 400fr 600fr;
  display: grid;
  row-gap: 32px;
  column-gap: 32px;
}
.filterSection {
  display: flex;
  row-gap: 16px;
  flex-wrap: wrap;
  column-gap: 16px;
  margin-top: 32px;
}

.filterGroup {
  order: solid 2px #303550;
  display: flex;
  border-radius: 8px;
  background-color: #262a40;
  width: fit-content;
  flex-grow: 0;
  flex-basis: auto;
  flex-shrink: 0;
}

.filterGroupFirst {
  margin-right: auto;
}

.filterItem {
  color: #8a8ea8;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 6px 25px;
  flex-grow: 1;
  font-size: 16px;
  box-shadow: none;
  flex-shrink: 0;
  font-weight: 500;
  line-height: 24px;
  border-radius: 6px;
  text-transform: none;
  background-color: inherit;
  font-weight: 300;
  font-size: 0.9rem;
}

.filterItem:hover,
.filterActive {
  background-color: #303550;
  color: #fff;
}

.filterItemEnd {
  flex-grow: 0;
  flex-basis: auto;
  flex-shrink: 0;
}

.chainImage {
  width: 30px;
  height: 30px;
  margin-top: 5px;
  border-radius: 8px;
}

.searchTokens,
.searchTokens:focus {
  background-color: #08091c;
  color: #fff;
  font-weight: 300;
  font-size: 0.825rem;
}

.form-select {
  font-weight: 200;
  font-size: 0.825rem;
  color: #c1c1c1;
}
.topSectionStats p {
  margin-top: 10px;
}
.topSectionStats {
  text-align: right;
  font-weight: 300;
  font-size: 0.9rem;
}
.topSectionVaults {
  margin-top: 25px;
}

.light .topSectionh2,
.light .topSectionVaults p {
  color: #333;
}
.topSectionh2 {
  font-size: 1.25rem;
  color: #d5d5d5;
}

.topSectionVaults p {
  font-size: 0.9rem;
  font-weight: 300;
  color: #b9b9b9;
}

.topwarning {
  display: block;
  margin-bottom: 25px;
  text-align: center;
  font-size: 0.875rem;
}

.tooltips {
  display: inline-block;
  position: relative;
  text-align: left;
}

.tooltips .bottom {
  min-width: 200px;
  top: 55px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 5px 20px;
  color: #888888;
  background-color: #000000;
  font-weight: normal;
  font-size: 12px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  border: 1px solid #888888;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
}

.tooltips:hover .bottom {
  visibility: visible;
  opacity: 1;
}

.tooltips .bottom i {
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -10px;
  width: 20px;
  height: 10px;
  overflow: hidden;
}

.tooltips .bottom i::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #000000;
  border: 1px solid #888888;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

.tooltips p {
  margin-bottom: 0;
  text-align: center;
}

.notactive img:hover {
  opacity: 1;
}
.notactive img {
  opacity: 0.4;
  filter: alpha(opacity=40); /* msie */
}

.networkSelect {
  align-self: flex-end;
}

@media only screen and (max-width: 1200px) {
  .filterItem {
    padding: 6px 15px;
  }
}

@media only screen and (max-width: 1000px) {
  .statSectionOuter {
    grid-template-columns: 1000fr;
  }

  .statSectionLeft {
    margin-right: auto;
  }

  .statSection {
    text-align: left;
  }

  .statSectionRight {
    margin-right: auto;
  }

  .statsEnd {
    justify-content: flex-start;
  }

  .filterGroup {
    width: 100%;
    text-align: center;
  }
}
@media only screen and (max-width: 650px) {
  .statSectionBelow {
    font-size: 20px;
  }
}
</style>
