<script setup>
import { ref } from "vue";
import { useStore } from "../store";
import {
  cutAddress,
  formatBalance,
  convertToEther,
  copyToClipBoard,
} from "../util/helpers";

const store = useStore();
const open = ref(false);
const copied = ref(false);

async function disconnectWallet() {
  await store.disconnect();
  open.value = false;
}

async function clipboardAction() {
  copyToClipBoard(store.account);
  copied.value = true;
  setTimeout(() => {
    copied.value = false;
  }, 2000);
}
</script>

<template>
  <span class="personSpan" v-if="store.balance"
    >{{ formatBalance(convertToEther(store.balance), 2) }}
    <img
      :src="require(`@/assets/tokens/${store.image}`)"
      v-if="store.image"
      class="chainIcon"
  /></span>
  <span class="walletSpan" @click="open = true" v-if="store.account">{{
    cutAddress(store.account)
  }}</span>

  <div class="modalConnect" v-if="open">
    <div class="modal-dialogs">
      <div class="modal-content">
        <div class="modal-body">
          <div class="flex flex-col">
            <h3 class="connectAWallet">
              Wallet Info<span class="closeConnectWallet" @click="open = false"
                >X
              </span>
            </h3>

            <span class="text-white"
              >Address:
              <span class="addressCopy">{{
                cutAddress(store.account)
              }}</span></span
            ><span class="clipboard" @click.prevent="clipboardAction()"
              ><font-awesome-icon icon="fa-solid fa-copy" />
              <span class="copied" v-if="copied">Copied!</span>
            </span>

            <div @click="disconnectWallet" class="walletConnectButton toppad">
              <span class="text-white">Disconnect Wallet </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.clipboard {
  padding-left: 10px;
  font-size: 0.8rem;
  cursor: pointer;
}

.clipboard:hover {
  color: #fff2e3;
}

.copied {
  font-size: 0.7rem;
  padding-left: 8px;
}

.addressCopy {
  font-size: 0.8rem;
  color: #fff2e3;
}
</style>
