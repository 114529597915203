<template>
  <div class="container">
    <div class="row farms">
      <div class="col-md-2">
        <Sidebar
          :dogepup="dogepup"
          :doge="doge"
          :filterParent="filterBoolean"
          @updateFilter="updateFilter"
        />
      </div>
      <div class="col-md-10">
        <div v-if="tokenData">
          <TopSection
            :tokenData="tokenData"
            :totalPending="totalPending"
            :totalStakedAmount="totalStakedAmount"
            :tvl="tvl"
          />
        </div>
        <div v-if="pools.length > 0">
          <div v-for="pool in filterPool()" :key="pool.id">
            <Pool
              :did="pool.id"
              :tag="pool.tag"
              :lp="pool.lp"
              :allocPoint="pool.allocPoint"
              :depositFee="pool.depositFee"
              :tokenName="pool.tokenName"
              :shortName="pool.shortName"
              :stakeToken="pool.stakeToken"
              :stakeTokenDecimals="pool.stakeTokenDecimals"
              :rewardTokenName="pool.rewardTokenName"
              :rewardToken="pool.rewardToken"
              :masterchef="pool.masterchef"
              :pid="pool.pid"
              :token0="pool.token0"
              :token0Name="pool.token0Name"
              :token0Decimals="pool.token0Decimals"
              :token1="pool.token1"
              :token1Name="pool.token1Name"
              :token1Decimals="pool.token1Decimals"
              :liquidityAdd="pool.liquidityAdd"
              :liquidityRemove="pool.liquidityRemove"
              :buyToken0="pool.buyToken0"
              :buyToken1="pool.buyToken1"
              :blockString="pool.blockString"
              :pendingString="pool.pendingString"
              :perSecond="pool.perSecond"
              @updateTVL="updateTVL"
              @updateStakedValue="updateStakedValue"
              @updatePending="updatePending"
              @updateUserVaults="updateUserVaults"
            />
          </div>
        </div>
        <div v-else><PoolsSkeleton /></div>
      </div>
    </div>
  </div>
</template>

<script>
import Pool from "../components/farm/Pool.vue";
import Pools from "../abi/pools.json";
import PoolsSkeleton from "../components/farm/PoolsSkeleton.vue";
import Sidebar from "../components/farm/SidebarFarm.vue";
import ERC20ABI from "../abi/ERC20.json";
import Web3 from "web3";
import TopSection from "../components/farm/TopSectionFarm.vue";
export default {
  components: {
    PoolsSkeleton,
    Sidebar,
    Pool,
    TopSection,
  },
  data() {
    return {
      dogepup: 0,
      doge: 0,
      pools: Pools.pools,
      tokenData: null,
      filter: "",
      filterBoolean: true,
      tvl: 0,
      totalStakedAmount: 0,
      poolTVL: [],
      stakedTVL: [],
      userVaults: [],
      dex: null,
      searchTokens: null,
      totalPending: 0,
      pendingPool: [],
    };
  },
  setup() {},
  methods: {
    updateFilter(type) {
      this.filterBoolean = true;
      this.filter = type;
    },
    updateTVL(id, amount) {
      if (!this.poolTVL.includes(id)) {
        this.poolTVL.push(id);
        this.tvl += parseFloat(amount);
      }
    },
    updateStakedValue(id, amount) {
      if (id == 1) {
        this.loadToken();
        this.setPrices();
      }
      let data = [{ id: id, amount: amount }];

      let total = 0;
      let found = false;
      if (this.stakedTVL.length > 0) {
        for (let i = 0; i < this.stakedTVL.length; i++) {
          if (this.stakedTVL[i].id == data[0].id) {
            found = true;
            this.stakedTVL = Array.from(
              [...this.stakedTVL, ...data]
                .reduce((m, o) => m.set(o.id, o), new Map())
                .values()
            );

            break;
          }
        }
        if (!found) {
          this.stakedTVL.push(data[0]);
        }
      } else {
        this.stakedTVL.push(data[0]);
      }

      for (let p = 0; p < this.stakedTVL.length; p++) {
        total += parseFloat(this.stakedTVL[p].amount);
      }

      this.totalStakedAmount = total;
    },
    updateUserVaults(id, action) {
      console.log(`${action}: ${id}`);
      if (action == "add") {
        if (!this.userVaults.includes(id)) {
          this.userVaults.push(id);
        }
      } else {
        if (this.userVaults.includes(id)) {
          for (let i = 0; i < this.userVaults.length; i++) {
            if (this.userVaults[i] === id) {
              this.userVaults.splice(i, 1);
            }
          }
        }
      }
    },
    filterPool() {
      if (this.filter == "my") {
        return this.pools.filter((pool) => this.userVaults.includes(pool.id));
      }

      return this.pools;
    },
    async setPrices() {
      this.doge = await this.fetchPrice(
        "0xB7ddC6414bf4F5515b52D8BdD69973Ae205ff101"
      );
      this.dogepup = await this.fetchPrice(
        "0x1b15b9446b9f632a78396a1680DAaE17f74Ce8d9"
      );
    },
    updatePending(data) {
      let pending = 0;
      let found = false;
      if (this.pendingPool.length > 0) {
        for (let i = 0; i < this.pendingPool.length; i++) {
          if (this.pendingPool[i].id == data[0].id) {
            found = true;
            this.pendingPool = Array.from(
              [...this.pendingPool, ...data]
                .reduce((m, o) => m.set(o.id, o), new Map())
                .values()
            );

            break;
          }
        }
        if (!found) {
          this.pendingPool.push(data[0]);
        }
      } else {
        this.pendingPool.push(data[0]);
      }

      for (let p = 0; p < this.pendingPool.length; p++) {
        pending += parseFloat(this.pendingPool[p].pending);
      }

      this.totalPending = pending;
    },
    async loadToken() {
      console.log("load token...");
      let web3 = new Web3(
        new Web3.providers.HttpProvider("https://rpc.kibbleswap.dog")
      );

      let user = this.$store.state.web3.coinbase
        ? this.$store.state.web3.coinbase
        : "0x2ddb73bdC6b086D4959D4Af87C0Ff05438dD66a7";

      let contract = new web3.eth.Contract(
        ERC20ABI,
        "0x1b15b9446b9f632a78396a1680DAaE17f74Ce8d9"
      );

      let price = await this.fetchPrice(
        "0x1b15b9446b9f632a78396a1680DAaE17f74Ce8d9"
      );

      let name = "DOGE PUP TOKEN";
      let symbol = "DOGEPUP";
      let decimals = 18;

      let supply, burned, balance;

      [supply, burned, balance] = await this.makeBatchRequest(
        [
          contract.methods.totalSupply().call,
          contract.methods.balanceOf(
            "0x000000000000000000000000000000000000dEaD"
          ).call,
          contract.methods.balanceOf(user).call,
        ],
        web3
      );

      let tokenData = {
        address: "0x1b15b9446b9f632a78396a1680DAaE17f74Ce8d9",
        name,
        symbol,
        total_supply: web3.utils.fromWei(supply.toString(), "ether"),
        burned: web3.utils.fromWei(burned.toString(), "ether"),
        decimals,
        price,
        balance: this.convertDecimals(balance, decimals),
      };

      this.tokenData = tokenData;

      return tokenData;
    },
    convertDecimals(balance, decimals) {
      if (decimals == 18) {
        return Web3.utils.fromWei(balance.toString(), "ether");
      } else {
        return balance / 10 ** decimals;
      }
    },
    makeBatchRequest(calls, web3) {
      let batch = new web3.BatchRequest();

      let promises = calls.map((call) => {
        return new Promise((res, rej) => {
          let req = call.request((err, data) => {
            if (err) rej(err);
            else res(data);
          });
          batch.add(req);
        });
      });
      batch.execute();

      return Promise.all(promises);
    },
    async fetchPrice(address) {
      return new Promise((resolve) => {
        fetch(
          `https://api.polypup.finance/v1/price?network=doge&address=${address}`
        )
          .then((res) => res.json())
          .then((data) => {
            if (data.status == "ok") {
              resolve(data.price);
            }
          });
      });
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
  },
  async mounted() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", async () => {
        await this.sleep(1000);
        this.loadToken();
      });

      window.ethereum.on("chainChanged", async () => {
        await this.sleep(1000);
        this.loadToken();
      });
    }
    await this.setPrices();
    await this.sleep(1000);
    await this.loadToken();
  },
};
</script>
