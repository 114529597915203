import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home";
import Farm from "../views/Farm";
import Chart from "../views/Chart";
import Marketplace from "../views/Marketplace";
import Account from "../views/Marketplace/account";
import NotFound from "../views/NotFound";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/farm",
    name: "Farm",
    component: Farm,
  },
  {
    path: "/chart",
    name: "Chart",
    component: Chart,
  },
  {
    path: "/marketplace",
    name: "NFTs Marketplace",
    component: Marketplace,
  },
  {
    path: "/marketplace/account",
    name: "NFTs Account",
    component: Account,
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(() => {
  window.scrollTo(0, 0);
});

export default router;
