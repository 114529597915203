<template>
  <div class="card more">
    <div class="card-body">
      <div class="bottomSection" @click="more ? (more = false) : (more = true)">
        <div class="poolTitleSection">
          <div class="mid">
            <span class="lpImage imageLeft"
              ><img
                :src="require(`@/assets/tokens/${token0.toLowerCase()}.png`)"
            /></span>
            <span class="lpImage imageRight"
              ><img
                :src="require(`@/assets/tokens/${token1.toLowerCase()}.png`)"
            /></span>
          </div>
          <div class="poolTitleInner">
            <div class="topLess">
              <span class="nameTitle">{{ shortName }}</span>
            </div>
            <div class="bottomLess small">
              <span class="dexTitle"
                ><a
                  :title="`Link to ${dex}`"
                  class="topDexLink"
                  :href="dexLink"
                  target="_blank"
                  rel="noreferrer"
                >
                  {{ dex }}
                  <font-awesome-icon
                    icon="fa-solid fa-square-arrow-up-right"
                  /> </a
              ></span>
            </div>
          </div>
        </div>
        <div class="poolStatsSection">
          <div class="poolStatsInner">
            <div class="poolStat">
              <div class="poolStatTop">Wallet</div>
              <div class="poolStatBottom">
                ${{ formatBalance(unstaked * tokenPrice, 2) }}
              </div>
            </div>
            <div class="poolStat">
              <div class="poolStatTop">Deposited</div>
              <div class="poolStatBottom">
                ${{ formatBalance(total_value * tokenPrice, 2) }}
              </div>
            </div>
            <div class="poolStat tooltips infoTitle">
              <div class="poolStatTop">APY</div>
              <div class="poolStatBottom apy" v-if="apy > 20000000">20M%</div>
              <div class="poolStatBottom apy" v-else>
                {{ formatBalance(apy, 2) }}%
              </div>
              <div class="bottom">
                <table>
                  <tr>
                    <td>APR:&nbsp;</td>
                    <td>{{ formatBalance(apr * 100, 2) }}%</td>
                  </tr>
                  <tr>
                    <td>Daily:&nbsp;</td>
                    <td>{{ formatBalance((apr * 100) / 365, 3) }}%</td>
                  </tr>
                  <tr>
                    <td>APY:&nbsp;</td>
                    <td>{{ formatBalance(apy, 2) }}%</td>
                  </tr>
                </table>
                <i></i>
              </div>
            </div>
            <div class="poolStat">
              <div class="poolStatTop">Earned</div>
              <div class="poolStatBottom">
                ${{ formatBalance(total_earned, 2) }}
              </div>
            </div>
            <div class="poolStat tooltips infoTitle">
              <div class="poolStatTop">Pending</div>
              <div class="poolStatBottom" v-if="userPercent > 0">
                ${{ formatFunds(totalPending * userPercent * rewardPrice) }}
              </div>
              <div class="poolStatBottom" v-else>$0</div>
              <div class="bottomMid">
                <table>
                  <tr>
                    <td>Your Pending:&nbsp;</td>
                    <td>
                      ${{
                        formatFunds(totalPending * userPercent * rewardPrice)
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Total Pending:&nbsp;</td>
                    <td>${{ formatBalance(totalPending * rewardPrice) }}</td>
                  </tr>
                </table>
                <i></i>
              </div>
            </div>
            <div class="poolStat">
              <div class="poolStatTop">TVL</div>
              <div class="poolStatBottom">
                ${{ formatFunds(staked_tvl * tokenPrice) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row bottomHalf" v-if="more">
        <div class="col-md-8">
          <div class="leftInfoWrapper">
            <div class="leftInfoOuter">
              <div class="leftInfoInner">
                <div class="leftInfoContain">
                  <div class="leftInfoItem">
                    <div class="leftInfoTitle">Chain</div>
                    <div class="leftInfoText">Doge Chain</div>
                  </div>
                </div>
                <div class="leftInfoContain">
                  <hr class="leftInfoHr" />
                  <div class="leftInfoItem">
                    <div class="leftInfoTitle">Platform</div>
                    <div class="leftInfoText">
                      {{ dex }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="leftInfoOuter">
              <div class="leftInfoInner">
                <div class="leftInfoContain">
                  <div class="leftInfoItem">
                    <div class="leftInfoTitle">Staked</div>
                    <div class="leftInfoText">
                      <span v-if="total_value * tokenPrice >= 1">
                        ${{ formatBalance(total_value * tokenPrice, 2) }}
                      </span>
                      <span
                        v-else-if="
                          total_value * tokenPrice < 1 &&
                          total_value * tokenPrice > 0
                        "
                      >
                        ${{ formatBalance(total_value * tokenPrice, 6) }}
                      </span>
                      <span v-else> $0 </span>
                    </div>
                  </div>
                </div>
                <div class="leftInfoContain">
                  <hr class="leftInfoHr" />
                  <div class="leftInfoItem">
                    <div class="leftInfoTitle">Earned</div>
                    <div class="leftInfoText">
                      <span v-if="total_earned >= 1"
                        >${{ formatBalance(total_earned, 2) }}</span
                      >
                      <span v-else-if="total_earned < 1 && total_earned > 0"
                        >${{ formatBalance(total_earned, 6) }}</span
                      >
                      <span v-else>$0</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="leftInfoWrapper">
            <div class="leftInfoOuter">
              <div class="leftInfoInner">
                <div class="leftInfoContain">
                  <div class="leftInfoItem">
                    <div class="leftInfoTitle infoTitle tooltips">
                      APY <font-awesome-icon icon="fa-solid fa-circle-info" />
                      <div class="bottom">
                        <table>
                          <tr>
                            <td>APR:&nbsp;</td>
                            <td>{{ formatBalance(apr * 100, 2) }}%</td>
                          </tr>
                          <tr>
                            <td>Daily:&nbsp;</td>
                            <td>{{ formatBalance((apr * 100) / 365, 3) }}%</td>
                          </tr>
                          <tr>
                            <td>APY:&nbsp;</td>
                            <td>{{ formatBalance(apy, 2) }}%</td>
                          </tr>
                        </table>
                        <i></i>
                      </div>
                    </div>
                    <div class="leftInfoText">{{ formatBalance(apy, 0) }}%</div>
                  </div>
                </div>
                <div class="leftInfoContain">
                  <hr class="leftInfoHr" />
                  <div class="leftInfoItem">
                    <div class="leftInfoTitle">Daily</div>
                    <div class="leftInfoText">
                      {{ formatBalance((apr * 100) / 365, 4) }}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="leftInfoOuter">
              <div class="leftInfoInner">
                <div class="leftInfoContain">
                  <div class="leftInfoItem">
                    <div class="leftInfoTitle">Your Pending</div>
                    <div class="leftInfoText">
                      ${{
                        formatBalance(totalPending * userPercent * rewardPrice)
                      }}
                    </div>
                  </div>
                </div>
                <div class="leftInfoContain">
                  <hr class="leftInfoHr" />
                  <div class="leftInfoItem">
                    <div class="leftInfoTitle">Total Pending</div>
                    <div class="leftInfoText">
                      ${{ formatBalance(totalPending * rewardPrice) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 3rd row -->
          <div class="strategyWrapper">
            <div class="strategyOuter">
              <div><h2>Strategy</h2></div>
              <div class="strategyLinks">
                <div>
                  <a
                    :href="`https://explorer.dogechain.dog/address/${contract}`"
                    target="_blank"
                    rel="noreferrer"
                    class="strategyLink"
                  >
                    Vault Contract &nbsp;
                    <font-awesome-icon
                      icon="fa-solid fa-square-arrow-up-right"
                    />
                  </a>
                </div>
                <div>
                  <a
                    :href="`https://explorer.dogechain.dog/address/${masterchef}`"
                    target="_blank"
                    rel="noreferrer"
                    class="strategyLink"
                  >
                    Target Contract
                    <font-awesome-icon
                      icon="fa-solid fa-square-arrow-up-right"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div class="strategyText">
              <div class="strategyTextInner">
                This vault deposits the user's
                <a
                  target="_blank"
                  :href="`https://explorer.dogechain.dog/address/${stakeToken}`"
                  >{{ shortName }}</a
                >
                in the {{ dex }} farm, earning
                <a
                  :href="`https://explorer.dogechain.dog/address/${rewardToken}`"
                  target="_blank"
                  >{{ rewardTokenName }}</a
                >, the platform's governance token. Earned tokens are swapped
                for more of the underlying assets in order to acquire more of
                the same liquidity token. To complete the compounding cycle, the
                new {{ shortName }} is added to the farm, compounding
                periodically throughout the day. The transaction cost required
                to do all this is provided by the platform.
              </div>
            </div>
          </div>

          <div class="strategyWrapper">
            <div class="strategyOuter">
              <div><h2>Token Data</h2></div>
            </div>
            <div class="assetContainer">
              <div class="assetOuter">
                <div class="assetInner">
                  <div class="assetItem">
                    <div class="assetItemImage">
                      <img
                        :src="
                          require(`@/assets/tokens/${token0.toLowerCase()}.png`)
                        "
                      />
                    </div>
                    <div class="assetItemName">{{ token0Name }}</div>
                  </div>
                  <div class="assetLinks">
                    <div class="assetLink">
                      <a
                        :href="`https://explorer.dogechain.dog/address/${token0}`"
                        class="strategyLink"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Contract Address
                        <font-awesome-icon
                          icon="fa-solid fa-square-arrow-up-right"
                        />
                      </a>
                    </div>
                    <div class="assetLink">
                      <a
                        class="strategyLink addMetamask"
                        rel="noreferrer"
                        @click.prevent="
                          addToMetamask(
                            token0,
                            token0Name,
                            token0Decimals,
                            require(`@/assets/tokens/${token1.toLowerCase()}.png`)
                          )
                        "
                      >
                        Add to Metamask
                        <img
                          src="@/assets/metamask.png"
                          class="metamaskImage"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="assetOuter">
                <div class="assetInner">
                  <div class="assetItem">
                    <div class="assetItemImage">
                      <img
                        :src="
                          require(`@/assets/tokens/${token1.toLowerCase()}.png`)
                        "
                      />
                    </div>
                    <div class="assetItemName">{{ token1Name }}</div>
                  </div>
                  <div class="assetLinks">
                    <div class="assetLink">
                      <a
                        :href="`https://explorer.dogechain.dog/address/${token1}`"
                        class="strategyLink"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Contract Address
                        <font-awesome-icon
                          icon="fa-solid fa-square-arrow-up-right"
                        />
                      </a>
                    </div>
                    <div class="assetLink">
                      <a
                        class="strategyLink addMetamask"
                        rel="noreferrer"
                        @click.prevent="
                          addToMetamask(
                            token1,
                            token1Name,
                            token1Decimals,
                            require(`@/assets/tokens/${token1.toLowerCase()}.png`)
                          )
                        "
                      >
                        Add to Metamask
                        <img
                          src="@/assets/metamask.png"
                          class="metamaskImage"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="assetOuter">
                <div class="assetInner">
                  <div class="assetItem">
                    <div class="assetItemImage itemImageLp">
                      <img
                        :src="
                          require(`@/assets/tokens/${token0.toLowerCase()}.png`)
                        "
                      />
                      <img
                        :src="
                          require(`@/assets/tokens/${token1.toLowerCase()}.png`)
                        "
                      />
                    </div>
                    <div class="assetItemName">{{ shortName }}</div>
                  </div>
                  <div class="assetLinks">
                    <div class="assetLink">
                      <a
                        :href="`https://explorer.dogechain.dog/address/${stakeToken}`"
                        class="strategyLink"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Contract Address
                        <font-awesome-icon
                          icon="fa-solid fa-square-arrow-up-right"
                        />
                      </a>
                    </div>
                    <div class="assetLink">
                      <a
                        class="strategyLink addMetamask"
                        rel="noreferrer"
                        @click.prevent="
                          addToMetamask(
                            stakeToken,
                            shortName,
                            18,
                            require(`@/assets/tokens/${token1.toLowerCase()}.png`)
                          )
                        "
                      >
                        Add to Metamask
                        <img
                          src="@/assets/metamask.png"
                          class="metamaskImage"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="assetOuter"
                v-if="
                  rewardToken.toLowerCase() != token0.toLowerCase() &&
                  rewardToken.toLowerCase() != token1.toLowerCase()
                "
              >
                <div class="assetInner">
                  <div class="assetItem">
                    <div class="assetItemImage">
                      <img
                        :src="
                          require(`@/assets/tokens/${rewardToken.toLowerCase()}.png`)
                        "
                      />
                    </div>
                    <div class="assetItemName">{{ rewardTokenName }}</div>
                  </div>
                  <div class="assetLinks">
                    <div class="assetLink">
                      <a
                        :href="`https://explorer.dogechain.dog/address/${rewardToken}`"
                        class="strategyLink"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Contract Address
                        <font-awesome-icon
                          icon="fa-solid fa-square-arrow-up-right"
                        />
                      </a>
                    </div>
                    <div class="assetLink">
                      <a
                        class="strategyLink addMetamask"
                        rel="noreferrer"
                        @click.prevent="
                          addToMetamask(
                            rewardToken,
                            rewardTokenName,
                            18,
                            require(`@/assets/tokens/${rewardToken.toLowerCase()}.png`)
                          )
                        "
                      >
                        Add to Metamask
                        <img
                          src="@/assets/metamask.png"
                          class="metamaskImage"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="leftInfoWrapperOne">
            <div class="leftInfoOuter">
              <div class="leftInfoInner">
                <div class="leftInfoContain">
                  <div class="leftInfoItem">
                    <div class="leftInfoTitle">Last Harvest</div>
                    <div class="leftInfoText infoTitle" @click="harvest">
                      {{ lastHarvested }}
                      <font-awesome-icon icon="fa-solid fa-calculator" />
                    </div>
                  </div>
                </div>
                <div class="leftInfoContain">
                  <hr class="leftInfoHr" />
                  <div class="leftInfoItem">
                    <div class="leftInfoTitle">{{ rewardTokenName }} Price</div>
                    <div class="leftInfoText" v-if="rewardPrice < 1">
                      ${{ formatBalance(rewardPrice, 6) }}
                    </div>
                    <div class="leftInfoText" v-else>
                      ${{ formatBalance(rewardPrice, 2) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="actionContainer">
            <div class="actionButtons">
              <div
                class="actionButton"
                :class="depositDiv ? 'activeButton' : ''"
                @click="depositShow()"
              >
                <span class="actionText">Deposit</span>
              </div>
              <div
                class="actionButton"
                :class="withdrawDiv ? 'activeButton' : ''"
                @click="withdrawShow()"
              >
                <span class="actionText">Withdraw</span>
              </div>
            </div>
            <div class="actionContent">
              <div class="depositDiv" v-if="depositDiv">
                <div class="walletAmount">
                  <div class="walletAmountInner">
                    <div class="walletAmountInnerText">Wallet:</div>
                    <div class="walletAmountInnerBelow">
                      <div class="walletAmountImages">
                        <img
                          class="leftImage"
                          :src="
                            require(`@/assets/tokens/${token0.toLowerCase()}.png`)
                          "
                        />
                        <img
                          class="rightImage"
                          :src="
                            require(`@/assets/tokens/${token1.toLowerCase()}.png`)
                          "
                        />
                      </div>
                      <div class="walletAmountBalance">
                        {{
                          unstaked.toLocaleString("fullwide", {
                            useGrouping: false,
                          })
                        }}
                        (${{ formatBalance(unstaked * tokenPrice) }})
                      </div>
                    </div>
                    <div class="walletInputOuter">
                      <div class="walletInputInner">
                        <input
                          type="number"
                          class="walletInput"
                          placeholder="0.0"
                          v-model="depositAmount"
                        />
                        <div
                          class="walletInputMax"
                          @click="maxDeposit(unstaked)"
                        >
                          Max
                        </div>
                      </div>
                    </div>
                    <div class="infoSection">
                      <div class="infoSectionOuter">
                        <div class="infoSectionFees">Fees</div>
                      </div>
                      <div class="feeSectionOuter">
                        <div class="infoSectionInner">
                          <div class="feeTitle">Deposit Fees</div>
                          <div class="feeValue">0%</div>
                        </div>
                        <div class="infoSectionInner">
                          <div class="feeTitle">Withdrawal Fees</div>
                          <div class="feeValue">0%</div>
                        </div>
                      </div>
                    </div>
                    <div class="linkSection">
                      <div class="linkItem">
                        <a :href="liquidityAdd" target="_blank" class="linkText"
                          >Add Liquidity
                          <font-awesome-icon
                            icon="fa-solid fa-square-arrow-up-right"
                        /></a>
                      </div>
                      <div class="linkItem">
                        <a
                          href="https://www.darkswap.finance/swap"
                          target="_blank"
                          class="linkText"
                          >Buy Token
                          <font-awesome-icon
                            icon="fa-solid fa-square-arrow-up-right"
                        /></a>
                      </div>
                    </div>

                    <div class="actionSection">
                      <div
                        class="actionButtonPlus"
                        @click="connectWallet()"
                        v-if="!store.account"
                      >
                        Connect Wallet
                      </div>
                      <div
                        v-if="
                          parseInt(chain_id) != parseInt(chainId) &&
                          store.account
                        "
                        class="actionButtonPlus"
                        @click="store.switchNetwork(chain_id)"
                      >
                        Switch to {{ correctChain }}
                      </div>
                      <div v-else-if="store.account">
                        <div v-if="parseInt(allowance) == 0">
                          <div
                            class="actionButtonPlus"
                            @click="approveContract()"
                            v-if="approveDisable"
                          >
                            Approve Contract
                          </div>
                          <div
                            class="actionButtonPlus actionDisabled"
                            @click="approveContract()"
                            v-else
                          >
                            Approving
                            <font-awesome-icon icon="fa-solid fa-spinner" />
                          </div>
                        </div>
                        <div v-if="parseInt(allowance) > 0">
                          <div
                            class="actionButtonPlus"
                            @click="deposit()"
                            v-if="stakeDisable"
                          >
                            Deposit
                          </div>
                          <div class="actionButtonPlus actionDisabled" v-else>
                            Depositing
                            <font-awesome-icon icon="fa-solid fa-spinner" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="depositDiv" v-if="withdrawDiv">
                <div class="walletAmount">
                  <div class="walletAmountInner">
                    <div class="walletAmountInnerText">Deposited:</div>
                    <div class="walletAmountInnerBelow">
                      <div class="walletAmountImages">
                        <img
                          class="leftImage"
                          :src="
                            require(`@/assets/tokens/${token0.toLowerCase()}.png`)
                          "
                        />
                        <img
                          class="rightImage"
                          :src="
                            require(`@/assets/tokens/${token1.toLowerCase()}.png`)
                          "
                        />
                      </div>
                      <div class="walletAmountBalance">
                        {{ total_value }}
                      </div>
                    </div>
                    <div class="infoSection">
                      <div class="infoSectionOuter">
                        <div class="infoSectionFees">Fees</div>
                      </div>
                      <div class="feeSectionOuter">
                        <div class="infoSectionInner">
                          <div class="feeTitle">Deposit Fees</div>
                          <div class="feeValue">0%</div>
                        </div>
                        <div class="infoSectionInner">
                          <div class="feeTitle">Withdrawal Fees</div>
                          <div class="feeValue">0%</div>
                        </div>
                      </div>
                    </div>
                    <div class="linkSection">
                      <div class="linkItem">
                        <a
                          :href="liquidityRemove"
                          target="_blank"
                          class="linkText"
                          >Remove Liquidity
                          <font-awesome-icon
                            icon="fa-solid fa-square-arrow-up-right"
                        /></a>
                      </div>
                    </div>

                    <div class="actionSection">
                      <div
                        class="actionButtonPlus"
                        @click="connectWallet()"
                        v-if="!store.account"
                      >
                        Connect Wallet
                      </div>
                      <div
                        v-if="
                          parseInt(chain_id) != parseInt(chainId) &&
                          store.account
                        "
                        class="actionButtonPlus"
                        @click="store.switchNetwork(chain_id)"
                      >
                        Switch to {{ correctChain }}
                      </div>
                      <div v-else-if="store.account">
                        <div v-if="staked > 0">
                          <div
                            class="actionButtonPlus"
                            @click="withdrawAll()"
                            v-if="unstakeDisable"
                          >
                            Withdraw All
                          </div>
                          <div class="actionButtonPlus actionDisabled" v-else>
                            Withdrawing
                            <font-awesome-icon icon="fa-solid fa-spinner" />
                          </div>
                        </div>
                        <div class="actionButtonPlus actionDisabled" v-else>
                          Withdraw All
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createToast } from "mosha-vue-toastify";
import Web3 from "web3";
import { storeToRefs } from "pinia";
import { useStore } from "../../store";
import ERC20 from "../../abi/ERC20.json";
import poolVault from "../../abi/poolVault.json";
import Darkswap from "../../abi/Darkswap.json";
import Wojak from "../../abi/Wojak.json";
import Yodeswap from "../../abi/Yodeswap.json";
import StableDoin from "../../abi/StableDoin.json";
import Kibbleswap from "../../abi/Kibbleswap.json";
import { supportedChains } from "../../util/chains";
import {
  convertEtherToWei,
  convertWeiToEther,
  formatMoney,
} from "../../util/helpers";

export default {
  props: {
    rpc: String,
    chain_id: Number,
    chain_image: String,
    did: Number,
    dex: String,
    volume: Number,
    lpfee: Number,
    dexLink: String,
    contract: String,
    image: String,
    stakeTokenName: String,
    shortName: String,
    stakeToken: String,
    stakeTokenDecimals: Number,
    rewardTokenName: String,
    rewardToken: String,
    burnTokenName: String,
    burnToken: String,
    router: String,
    masterchef: String,
    pid: Number,
    weth: String,
    token0: String,
    token0Name: String,
    token0Decimals: Number,
    token1: String,
    token1Name: String,
    token1Decimals: Number,
    tokensPerYear: Number,
    liquidityAdd: String,
    liquidityRemove: String,
    blockString: String,
    perSecond: Boolean,
    abi: String,
  },
  setup() {
    const store = useStore();
    const { account, chainId } = storeToRefs(store);
    return {
      store,
      account,
      chainId,
    };
  },
  watch: {
    account() {
      this.fetchPool();
    },
    chainId() {
      this.fetchPool();
    },
  },
  data() {
    return {
      depositDiv: true,
      withdrawDiv: false,
      gwei: "312",
      more: false,
      unstakeDisable: true,
      stakeDisable: true,
      harvestDisable: true,
      approveDisable: true,
      increment: 30,
      staked_tvl: 0,
      staked_tvl_amount: 0,
      allowance: 0,
      pendingRewards: 0,
      staked: 0,
      unstaked: 0,
      yearlyRewards: 83011.4968,
      tokenPerShare: 0,
      harvestedTime: 0,
      currentDifference: 0,
      tokenPrice: 0,
      poolTVL: 0,
      totalPending: 0,
      depositAmount: "",
      apy: 0,
      apr: 0,
      rewardPrice: 0,
      userPercent: 0,
      totalValue: 0,
      harvestRewards: 0,
      stakingAtLastUserAction: 0,
      total_value: 0,
      total_earned: 0,
      amortization: [],
      lpapr: 0,
    };
  },
  async mounted() {
    await this.sleep(1000);

    await this.stakeTokenPrice();
    await this.fetchPool();
    await this.calculateAPR();
    this.calculateTime();

    this.getTVL();

    this.getUserVault();

    await this.getAmorization();

    this.pollInterval = setInterval(async () => {
      if (this.increment < 1 && !this.load) {
        this.load = true;
        await this.stakeTokenPrice();
        await this.fetchPool();
        await this.calculateAPR();
        this.calculateTime();
        this.getTVL();
        this.increment = 30;
        this.load = false;
      } else {
        if (!this.load) {
          this.increment = this.increment - 1;
        }
      }
    }, 1000);
  },
  beforeUnmount() {
    this.stop = true;
    clearInterval(this.pollInterval);
  },
  computed: {
    isDisabled: function () {
      return !this.unstakeDisable;
    },
    lastHarvested() {
      let harvest = this.$filters.timeAgo(this.currentDifference);
      harvest = harvest.replace(" minutes", "M");
      harvest = harvest.replace(" minute", "M");
      harvest = harvest.replace(" hours", "H");
      harvest = harvest.replace(" days", "D");
      harvest = harvest.replace("an hour", "1H");
      harvest = harvest.replace("a few seconds", "1S");
      return harvest;
    },
    correctChain() {
      return supportedChains(this.chain_id).name;
    },
  },
  methods: {
    async addToMetamask(tokenAddress, tokenSymbol, tokenDecimals, tokenImage) {
      try {
        await window.ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: tokenAddress,
              symbol: tokenSymbol,
              decimals: tokenDecimals,
              image: tokenImage,
            },
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
    depositShow() {
      this.depositDiv = true;
      this.withdrawDiv = false;
    },
    withdrawShow() {
      this.depositDiv = false;
      this.withdrawDiv = true;
    },
    maxDeposit() {
      this.depositAmount = this.unstaked;
    },
    async connectWallet() {
      this.store.connectWallet("metamask");
    },
    async getAmorization() {
      let daily = (this.apr + this.lpapr) / 365;

      for (let i = 1; i <= 365; i++) {
        let object;
        if (i == 1) {
          const earned = daily * (this.total_value * this.tokenPrice);
          object = {
            day: i,
            earned: earned,
            total: earned + this.total_value * this.tokenPrice,
          };
        } else {
          const earned = daily * this.amortization[i - 2].total;
          object = {
            day: i,
            earned: earned,
            total: earned + this.amortization[i - 2].total,
          };
        }
        this.amortization.push(object);
      }
    },
    getTVL() {
      const tvl = this.staked_tvl * this.tokenPrice;
      this.store.updateTVL(this.did, tvl, this.apy);
    },
    getUserVault() {
      if (this.staked > 0) {
        this.store.updateUserVaults(this.did, "add");
      } else {
        this.store.updateUserVaults(this.did, "remove");
      }
    },
    calculateTime() {
      this.currentDifference = new Date(Date.now() - this.harvestedTime * 1000);
    },
    async stakeTokenPrice() {
      const web3 = new Web3(new Web3.providers.HttpProvider(this.rpc));

      const t0 = new web3.eth.Contract(ERC20, this.token0);
      const t1 = new web3.eth.Contract(ERC20, this.token1);
      const lp = new web3.eth.Contract(ERC20, this.stakeToken);
      const p0 = await this.fetchPrice(this.token0);
      const p1 = await this.fetchPrice(this.token1);

      let q0, q1, total_supply;

      /*[q0, q1, total_supply] = await this.makeBatchRequest(
        [
          t0.methods.balanceOf(this.stakeToken).call,
          t1.methods.balanceOf(this.stakeToken).call,
          lp.methods.totalSupply().call,
        ],
        web3
      );
      */

      q0 = await t0.methods.balanceOf(this.stakeToken).call();
      q1 = await t1.methods.balanceOf(this.stakeToken).call();
      total_supply = await lp.methods.totalSupply().call();

      q0 = q0 / 10 ** this.token0Decimals;
      q1 = q1 / 10 ** this.token1Decimals;
      total_supply = total_supply / 10 ** this.stakeTokenDecimals;
      const tvl = q0 * p0 + q1 * p1;

      this.poolTVL = tvl;

      this.tokenPrice = tvl / total_supply;
    },
    async fetchPrice(address) {
      return new Promise((resolve) => {
        fetch(
          `https://api.polypup.finance/v1/price?network=doge&address=${address}`
        )
          .then((res) => res.json())
          .then((data) => {
            if (data.status == "ok") {
              resolve(data.price);
            }
          });
      });
    },
    lpTotals(tvl, lpPrice, userStaked, tokenPrice) {
      let totalSupply = tvl / lpPrice;
      let userPercent = userStaked / totalSupply;
      return this.formatBalance(userPercent * tokenPrice);
    },
    makeBatchRequest(calls, web3) {
      let batch = new web3.BatchRequest();

      let promises = calls.map((call) => {
        return new Promise((res, rej) => {
          let req = call.request((err, data) => {
            if (err) rej(err);
            else res(data);
          });
          batch.add(req);
        });
      });
      batch.execute();

      return Promise.all(promises);
    },
    async calculateAPR() {
      const compoundingPeriods = 365;
      const web3 = new Web3(new Web3.providers.HttpProvider(this.rpc));
      let abi;
      if (this.abi == "Darkswap") {
        abi = Darkswap;
      } else if (this.abi == "Wojak") {
        abi = Wojak;
      } else if (this.abi == "StableDoin") {
        abi = StableDoin;
      } else if (this.abi == "Kibbleswap") {
        console.log("Kibbleswap");
        abi = Kibbleswap;
      } else {
        abi = Yodeswap;
      }

      const chef = new web3.eth.Contract(abi, this.masterchef);
      let pool, totalAllocPoint, perBlock;

      pool = await chef.methods.poolInfo(this.pid).call();
      if (this.dex == "Wojak") {
        totalAllocPoint = await chef.methods.totalRegularAllocPoint().call();
        perBlock = await chef.methods[this.blockString](true).call();
        //perBlock = perBlock * 0.75;
      } else {
        totalAllocPoint = await chef.methods.totalAllocPoint().call();
        perBlock = await chef.methods[this.blockString]().call();
      }

      perBlock = web3.utils.fromWei(perBlock.toString(), "ether");

      if (this.dex == "Darkswap") {
        console.log("is here");
        console.log(`Per Block: ${perBlock}`);
      }
      //perBlock = 0.23;

      let tokensPerYear = this.perSecond
        ? 525600 * 60 * perBlock
        : (525600 * 60 * perBlock) / 2;

      let allocPoint = pool.allocPoint;
      let poolPercentage = allocPoint / totalAllocPoint;
      let poolTokens = tokensPerYear * poolPercentage;
      let tokenPrice = await this.fetchPrice(this.rewardToken);
      let rewardsPerYear = poolTokens * tokenPrice;

      this.rewardPrice = tokenPrice;

      let staked_tvl_amount = this.staked_tvl_amount * this.tokenPrice;

      let apr = rewardsPerYear / staked_tvl_amount;

      this.apr = apr;

      let getLpApr = this.getLpApr();

      let percentOfApr = apr * 0.135 * 100;

      apr += getLpApr;

      this.apy =
        ((1 + apr / compoundingPeriods) ** compoundingPeriods - 1) * 100 -
        percentOfApr;

      // console.log(`APY: ${this.apy}`);
    },
    getLpApr() {
      let volume = this.volume;
      let fee = this.lpfee;
      let weeks = 56;

      let feePerYear = volume * weeks * fee;

      let apr = feePerYear / this.poolTVL;

      this.lpapr = apr;

      let apy = (1 + apr / 365) ** 365 - 1;

      return apy;
    },
    async fetchPool() {
      let address = this.store.account
        ? this.store.account
        : "0x2ddb73bdC6b086D4959D4Af87C0Ff05438dD66a7";

      // if (window.ethereum) {
      const web3 = new Web3(new Web3.providers.HttpProvider(this.rpc));

      let stakeTokenContract = new web3.eth.Contract(ERC20, this.stakeToken);

      let stakingContract = new web3.eth.Contract(poolVault, this.contract);

      let allowance,
        tokenPerShare,
        harvestedTime,
        balanceUnstaked,
        staked_tvl_amount,
        userInfo,
        calculateTotalPendingRewards,
        calculateHarvestRewards,
        staked_tvl,
        balanceOf;

      allowance = await stakeTokenContract.methods
        .allowance(address, this.contract)
        .call();
      tokenPerShare = await stakingContract.methods
        .getPricePerFullShare()
        .call();
      harvestedTime = await stakingContract.methods.lastHarvestedTime().call();
      balanceUnstaked = await stakeTokenContract.methods
        .balanceOf(address)
        .call();
      staked_tvl_amount = await stakeTokenContract.methods
        .balanceOf(this.masterchef)
        .call();
      userInfo = await stakingContract.methods.userInfo(address).call();
      calculateTotalPendingRewards = await stakingContract.methods
        .calculateTotalPendingRewards()
        .call();
      calculateHarvestRewards = await stakingContract.methods
        .calculateHarvestRewards()
        .call();
      staked_tvl = await stakingContract.methods.totalShares().call();
      balanceOf = await stakingContract.methods.balanceOf().call();

      this.allowance = allowance;
      this.harvestedTime = harvestedTime;
      this.tokenPerShare = convertWeiToEther(tokenPerShare, 18);
      this.unstaked = convertWeiToEther(
        balanceUnstaked,
        this.stakeTokenDecimals
      );
      this.staked_tvl_amount = convertWeiToEther(
        staked_tvl_amount,
        this.stakeTokenDecimals
      );

      this.harvestRewards = convertWeiToEther(calculateHarvestRewards, 18);

      this.totalPending = convertWeiToEther(calculateTotalPendingRewards, 18);

      this.balanceOf = convertWeiToEther(balanceOf, 18);

      this.staked = convertWeiToEther(userInfo.amount, this.stakeTokenDecimals);

      this.stakingAtLastUserAction = convertWeiToEther(
        userInfo.stakingAtLastUserAction,
        this.stakeTokenDecimals
      );

      this.totalShares = convertWeiToEther(staked_tvl, this.stakeTokenDecimals);

      this.totalStaked = this.staked * this.tokenPerShare;

      this.userPercent = userInfo.amount / staked_tvl;

      this.total_value = this.totalStaked;

      this.store.updateUserTVL(this.did, this.total_value * this.tokenPrice);

      this.staked_tvl =
        convertWeiToEther(staked_tvl, this.stakeTokenDecimals) *
        this.tokenPerShare;

      this.total_earned =
        (this.total_value - this.stakingAtLastUserAction) * this.tokenPrice;

      // }
    },
    formatFunds(input) {
      return formatMoney(input);
    },
    formatBalance: (amount, decimal) => {
      return Number(amount).toLocaleString("en-US", {
        maximumFractionDigits: decimal,
      });
    },
    tokenReplace(string) {
      string = string.replace("[", "");
      string = string.replace("[", "");
      string = string.replace("]", "");
      string = string.replace("]", "");
      return string.toUpperCase();
    },
    formatAllocPoints(data) {
      return `${parseInt(data) / 100}x`;
    },
    unstakeToInput(val, id) {
      document.getElementById(id).value = val;
    },

    async deposit() {
      if (this.store.account) {
        this.stakeDisable = false;

        if (this.depositAmount == 0) {
          this.stakeIcon = "fa-plus";
          this.stakeDisable = true;
          createToast(`You must enter a value!`, {
            toastBackgroundColor: "red",
            transition: "bounce",
            type: "success",
          });
          return;
        }

        const newvalue = convertEtherToWei(
          this.depositAmount.toString(),
          this.stakingTokenDecimals
        );

        const tx = await this.store.deposit(this.contract, newvalue);

        if (tx.status) {
          await this.fetchPool();
          this.getUserVault();
          this.depositAmount = "";
          this.stakeDisable = true;
          createToast(`Transaction Success!`, {
            showIcon: true,
            toastBackgroundColor: "green",
            transition: "bounce",
            type: "success",
          });
        } else {
          this.stakeDisable = true;
          createToast(`Metamask error, try again...`, {
            showIcon: true,
            toastBackgroundColor: "red",
            transition: "bounce",
            type: "error",
          });
        }
      }
    },

    async withdrawAll() {
      if (this.store.account) {
        this.unstakeDisable = false;

        const tx = await this.store.withdrawAll(this.contract);
        if (tx.status) {
          await this.fetchPool();
          this.getUserVault();
          this.unstakeDisable = true;
          createToast(`Transaction Success`, {
            showIcon: true,
            toastBackgroundColor: "green",
            transition: "bounce",
            type: "success",
          });
        } else {
          this.unstakeDisable = true;
          this.unstakeIcon = "fa-minus";
          createToast(`Metamask error, try again...`, {
            showIcon: true,
            toastBackgroundColor: "red",
            transition: "bounce",
            type: "error",
          });
        }
      }
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },

    async harvest() {
      if (this.store.account) {
        this.harvestDisable = false;

        const tx = await this.store.harvest(this.contract);

        if (tx.status) {
          this.fetchPool();
          this.harvestDisable = true;
          createToast(`Transaction Success`, {
            showIcon: true,
            toastBackgroundColor: "green",
            transition: "bounce",
            type: "success",
          });
        } else {
          this.harvestDisable = true;
          createToast(`Metamask error, try again...`, {
            showIcon: true,
            toastBackgroundColor: "red",
            transition: "bounce",
            type: "error",
          });
        }
      }
    },

    async approveContract() {
      this.approveDisable = false;
      let address = this.store.account;
      let tokenContract = this.stakeToken;
      let masterchefContract = this.contract;
      if (this.store.account) {
        if (address && tokenContract && masterchefContract) {
          const tx = await this.store.approveContract(
            tokenContract,
            masterchefContract
          );
          if (tx.status) {
            this.fetchPool();
            this.approveDisable = true;
            createToast(`Transaction Success`, {
              showIcon: true,
              toastBackgroundColor: "green",
              transition: "bounce",
              type: "success",
            });
          } else {
            this.approveDisable = true;
            createToast(`Metamask error, try again...`, {
              showIcon: true,
              toastBackgroundColor: "red",
              transition: "bounce",
              type: "error",
            });
          }
        } else {
          this.approveDisable = true;
          createToast(`No address, token contract or masterchef detected...`, {
            toastBackgroundColor: "red",
            transition: "bounce",
            type: "success",
          });
        }
      } else {
        this.approveDisable = true;
        createToast(`No metamask detected...`, {
          toastBackgroundColor: "red",
          transition: "bounce",
          type: "success",
        });
      }
    },
  },
};
</script>

<style scoped>
.actionContainer {
  border-radius: 12px;
  background-color: #272b4a;
  margin-top: 32px;
}

.actionButtons {
  background-color: #111321;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.actionButton {
  color: #999cb3;
  width: 50%;
  height: 60px;
  margin: 0;
  padding: 0;
  background: none;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 16px;
  min-width: 64px;
  box-sizing: border-box;
  font-weight: 500;
  line-height: 24px;
  border-radius: 4px;
  text-transform: none;
  display: inline-flex;
  cursor: pointer;
}

.activeButton {
  border-bottom: 2px solid #f2a900;
}

.actionText {
  width: 100%;
  display: inherit;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 300;
}

.actionContent {
  padding: 18px;
}

.walletAmount {
  display: flex;
}

.walletAmountInner {
  width: 100%;
}
.walletAmountInnerText {
  color: #999cb3;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 4px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.walletAmountInnerBelow {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  line-height: 24px;
}

.walletAmountImages {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 8px;
  width: 50px;
  height: 24px;
  line-height: 24px;
}

.walletAmountImages img {
  width: 24px;
  height: 24px;
}

.rightImage {
  margin-left: -6px;
}

.walletAmountBalance {
  display: flex;
  font-size: 0.8rem;
  font-weight: 400;
}

.walletInputOuter {
  padding-top: 24px;
}

.walletInputInner {
  margin: 0;
  padding: 0;
  position: relative;
  box-shadow: none;
  border-radius: 8px;
  background-color: #111321;
  display: flex;
}

.walletInput {
  line-height: 24px;
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 5px 2px;
  display: block;
  padding: 7px 12px 7px;
  font-size: 16px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  font-weight: 500;
  line-height: 24px;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  text-transform: none;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}

.walletInputMax {
  color: #f5f5ff;
  padding: 6px 12px;
  display: flex;
  font-size: 0.65rem;
  min-width: 0;
  cursor: pointer;
  font-weight: 500;
  line-height: 20px;
  border-radius: 4px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  background-color: #363b63;
  line-height: 24px;
  margin: 4px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.infoSection {
  margin-top: 24px;
  padding: 18px;
  border-radius: 10px;
  background-color: #313759;
}

.infoSectionFees {
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;
  color: #8585a6;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 8px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.infoSectionOuter {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.infoSectionInner {
  flex-grow: 0;
  max-width: 50%;
  flex-basis: 50%;
  box-sizing: border-box;
}

.feeTitle {
  color: #999cb3;
  display: flex;
  font-size: 12px;
  align-items: center;
  font-weight: 500;
  line-height: 20px;
  padding-top: 5px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.feeValue {
  color: #d0d0da;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding-top: 0;
  text-transform: none;
}
.feeSectionOuter {
  display: flex;
}

.actionButtonPlus {
  width: 100%;
  color: #f5f5ff;
  padding: 12px 24px;
  border-radius: 8px;
  background-color: #4b9654;
  cursor: pointer;
  text-align: center;
}

.actionDisabled,
.actionDisabled:hover {
  opacity: 0.7;
  cursor: not-allowed;
}

.actionButtonPlus:hover {
  background-color: #3c7c43;
}

.actionSection {
  margin-top: 24px;
}

.poolBodyOuter {
  color: #9595b2;
  display: block;
  padding: 24px;
  position: relative;
  background: #2d3153;
  border-bottom: solid 2px #363b63;
  text-decoration: none;
}

.poolBodyInner {
  grid-template-columns: minmax(0, 30fr) minmax(0, 70fr);
  width: 100%;
  display: grid;
  row-gap: 24px;
  column-gap: 24px;
}

.poolBodyInnerTitle {
  display: flex;
  flex-grow: 0;
  min-width: 0;
  column-gap: 16px;
  flex-shrink: 0;
  flex-direction: row;
}

.poolBodyInnerStats {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}

.poolTitleSection {
  display: flex;
  flex-grow: 0;
  min-width: 0;
  column-gap: 16px;
  flex-shrink: 0;
  flex-direction: row;
}

.poolStatsSection {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}

.poolStatsInner {
  grid-template-columns: repeat(6, minmax(0, 1fr));
  width: 100%;
  display: grid;
  row-gap: 24px;
  column-gap: 24px;
}

.linkSection {
  display: flex;
  margin-top: 24px;
  gap: 12px;
}

.linkItem {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}

.linkText {
  color: #9595b2;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
  background-color: #363b63;
  border-radius: 8px;
  text-decoration: none;
  padding: 5px 10px;
}

.linkText:hover {
  background-color: #2d3153;
}

.leftInfoWrapper {
  display: grid;
  grid-template-columns: minmax(0, 500fr) minmax(0, 500fr);
  row-gap: 48px;
  column-gap: 32px;
  margin-top: 32px;
}

.leftInfoWrapperOne {
  display: grid;
  grid-template-columns: minmax(0, 1000fr);
  row-gap: 48px;
  column-gap: 48px;
  margin-top: 32px;
}

.leftInfoWrapperSingle {
  display: grid;
  grid-template-columns: minmax(0, 700fr) minmax(0, 300fr);
  row-gap: 48px;
  column-gap: 48px;
  margin-top: 32px;
}

.leftInfoOuter {
  width: 100%;
}

.extraMargin {
  margin-right: 32px;
}

.leftInfoHr {
  color: #363b63;
  width: 2px;
  margin-right: 24px !important;
  height: 100%;
  border: none;
  margin: 0;
  flex-shrink: 0;
  background-color: rgba(255, 255, 255, 0.12);
}

.leftInfoContain {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  width: 50%;
}
.leftInfoContainThirds {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  width: 33%;
}

.leftInfoContainSingle {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.textSmall {
  text-transform: none;
  font-size: 0.8rem;
}

.leftInfoInner {
  height: 96px;
  display: flex;
  padding: 16px 16px;
  border-radius: 8px;
  justify-content: flex-start;
  background-color: #232743;
}

.leftInfoItem {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.leftInfoTitle {
  color: #999cb3;
  font-size: 0.75rem;
  text-align: left;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.leftInfoText {
  margin: 0;
  padding: 0;
  font-size: 0.85rem;
  font-weight: 300;
  color: #fff;
  text-transform: uppercase;
  line-height: 24px;
  white-space: nowrap;
  margin-left: auto;
}

.tooltips {
  display: inline-block;
  position: relative;
  text-align: left;
}

.tooltips .bottom {
  min-width: 200px;
  top: 50%;
  left: 100%;
  transform: translate(0, -50%);
  margin-left: 20px;
  padding: 5px 20px;
  color: #888888;
  background-color: #000000;
  font-weight: normal;
  font-size: 12px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  border: 1px solid #888888;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
  cursor: pointer;
}

.tooltips:hover .bottom {
  visibility: visible;
  opacity: 1;
}

.tooltips .bottom i {
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -12px;
  width: 12px;
  height: 24px;
  overflow: hidden;
}

.tooltips .bottom i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 0;
  top: 50%;
  transform: translate(50%, -50%) rotate(-45deg);
  background-color: #000000;
  border: 1px solid #888888;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

.tooltips .bottomMid {
  min-width: 200px;
  top: 35px;
  left: 80%;
  transform: translate(-50%, 0);
  padding: 5px 20px;
  color: #888888;
  background-color: #000000;
  font-weight: normal;
  font-size: 12px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  border: 1px solid #888888;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
}

.tooltips:hover .bottomMid {
  visibility: visible;
  opacity: 1;
}

.tooltips .bottomMid i {
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -10px;
  width: 20px;
  height: 10px;
  overflow: hidden;
}

.assetOuter {
  padding: 16px;
  border-radius: 12px;
  background-color: #2d3153;
  margin-bottom: 18px;
}

.assetInner {
  display: flex;
}

.assetItem {
  display: flex;
}

.assetItemImage {
  width: 30px;
  height: 30px;
  display: flex;
}

.itemImageLp {
  width: 60px;
}

.metamaskImage {
  height: 15px;
  width: 15px;
}

.assetItemName {
  display: flex;
  min-width: 100px;
  max-width: 200px;
  align-items: center;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 300;
  margin-left: 8px;
}

.assetItemImage img {
  width: 100%;
  height: 100%;
}
.assetContainer {
  background-color: #232743;
  padding: 0px 18px 18px 18px;
}

.addMetamask {
  cursor: pointer;
  margin-left: 8px;
}

.assetLinks {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.tooltips .bottomMid i::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #000000;
  border: 1px solid #888888;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

.poolStatTop {
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 500;
  color: #9595b2;
  display: none;
}

.poolStatBottom {
  font-size: 0.9rem;
  font-weight: 300;
  color: #c6c6c6;
  margin-top: 4px;
}

.infoTitle {
  cursor: pointer;
}

.strategyWrapper {
  margin-top: 24px;
  border-radius: 20px;
  box-shadow: 0px 0px 32px 0px #0000001a;
}

.strategyOuter {
  padding: 24px;
  border-radius: 12px 12px 0 0;
  background-color: #232743;
  display: flex;
}

.strategyLinks {
  display: flex;
  row-gap: 8px;
  flex-wrap: wrap;
  column-gap: 8px;
  align-items: center;
  margin-left: auto;
}

.strategyOuter h2 {
  color: #f5f5ff;
  margin: 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.strategyLink {
  color: #d0d0da;
  padding: 4px 10px;
  font-size: 0.8rem;
  align-items: center;
  border-radius: 4px;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  background-color: #363b63;
}

.strategyLink:hover {
  background-color: #2d3153;
}

.strategyText {
  display: flex;
  padding: 24px;
  border-radius: 0 0 12px 12px;
  flex-direction: column;
  background-color: #373b64;
}

.strategyTextInner {
  color: #d0d0da;
  margin: 0 0 32px 0;
  white-space: pre-line;
}

.strategyTextInner a {
  color: #d0d0da;
  text-decoration: underline;
}

.strategyTextInner a:hover {
  text-decoration: none;
}

.poolStat {
  text-align: right;
}

.card-body {
  padding: 0;
}

.card {
  margin-top: 0px;
  margin-bottom: 0px;
}

.approval {
  background: #f7faff;
  border-radius: 5px;
}

.approval .btn {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 55px;
}

.harvest-amount {
  display: block;
  padding-bottom: 15px;
  padding-top: 15px;
  height: 56px;
}

.btnFooter {
  font-size: 0.675rem;
}

.harvest-amount .tokenName {
  float: left;
  font-weight: bold;
  padding-top: 10px;
  padding-left: 20px;
  font-size: 20px;
  text-transform: uppercase;
}

.harvest-amount .tokenAmount {
  float: right;
  padding-right: 20px;
  padding-top: 10px;
  font-size: 20px;
}

.harvest-section .tokenName {
  float: left;
  font-weight: bold;
  padding-left: 20px;
  font-size: 26px;
}

.more .topLess {
  font-size: 0.7rem;
  font-weight: 500;
  color: burlywood;
  cursor: pointer;
}

.more .bottomLess .ended {
  margin-left: 0;
  margin-right: 0;
}

.more .mid {
  display: flex;
  align-items: center;
  height: 48px;
}

.more {
  color: #9595b2;
  display: block;
  padding: 24px;
  position: relative;
  background: #2d3153;
  border-bottom: solid 2px #363b63;
  text-decoration: none;
}

.bottomSection {
  grid-template-columns: minmax(0, 30fr) minmax(0, 70fr);
  width: 100%;
  display: grid;
  row-gap: 24px;
  column-gap: 24px;
  cursor: pointer;
}

.mid .tokenTitle {
  font-size: 0.8rem;
  font-weight: 300;
}

.mid .lpImage {
  margin-right: 1px;
}

.mid .imageRight {
  margin-left: -13px;
}

.stats .topLess,
.stats .bottomLess {
  text-align: center;
}

.stakedBackground {
  border-left: green solid 6px;
}

.harvest-section .tokenAmount {
  float: right;
  padding-right: 20px;
}

.aprUsd {
  float: right;
  font-size: 10px;
  color: green;
}

.midSection {
  margin-bottom: 25px;
}

.table_content th {
  font-weight: 200;
}

.aprPercent {
  float: left;
}

.bottomHalf {
  margin-top: 15px;
  background-color: #1b1e31;
  border-radius: 12px;
  padding-bottom: 32px;
  padding-left: 20px;
  padding-right: 20px;
}

.small {
  font-weight: 300;
}

.tokenTitle {
  text-transform: uppercase;
}

.tokenBadges {
  float: right;
  margin-right: 10px;
}

.topwarning {
  display: block;
  margin-bottom: 25px;
  text-align: center;
  font-size: 0.875rem;
}

.form-control {
  font-size: 12px;
}

.strong {
  font-weight: bold;
}

.networkImage img {
  position: absolute;
  width: 24px;
  height: 24px;
  top: -5px;
  left: -5px;
  overflow: hidden;
}

.totalValue {
  display: block;
  text-align: center;
  font-size: 0.9rem;
}

.dark .aprUsd {
  color: #42ff75;
}

.ended {
  color: #fff;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.yodeswap {
  background-color: #6b4800;
}

.kibbleswap {
  background-color: #70016b;
}

.text-light {
  font-weight: 300;
}

.poolNew {
  background-color: #106d00;
  font-size: 0.8rem;
}

.poolOld {
  background-color: #7a000a;
  font-size: 0.8rem;
}

.dark .card-footer .text-muted {
  color: #d1d1d1 !important;
}

.dark .card-footer {
  background: rgb(47, 61, 117);
}

.dark .userStaked {
  color: #ccddfc;
}

.apy {
  font-weight: 700;
}

.userStaked {
  float: left;
  font-size: 12px;
  color: #333333;
}

.stk {
  width: 100px;
  font-size: 12px;
}

.dark .userStakedPrice {
  color: #ccddfc;
}

.userStakedPrice {
  float: right;
  font-size: 12px;
  color: #333333;
}

.table {
  width: 100%;
}

.card-body {
  padding-bottom: 0px;
}

.table tr th {
  min-width: 100px;
}

.nameTitle {
  color: darksalmon;
  font-size: 0.875rem;
}

.dexTitle {
  color: #efefef;
  font-size: 0.75rem;
  text-transform: uppercase;
  background: #4c5480;
  padding: 4px 8px;
  border-radius: 5px;
}

h4.card-title {
  color: #333333;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

/* This is the class name given by the Font Awesome component when icon contains 'spinner' */
.fa-spinner {
  /* Apply 'spinner' keyframes looping once every second (1s)  */
  animation: spinner 1s linear infinite;
}

.table_token {
  overflow: hidden;
  position: relative;
}

.topDexLink {
  color: #fff;
  text-decoration: none;
}

.light .topDexLink {
  color: #000;
}

.topDexLink:hover {
  text-decoration: underline;
}

.light .bottomHalf {
  background-color: #efefef;
}

.table_bg {
  opacity: 0.2;
  position: absolute;
  left: 0;
  top: 0px;
  width: 200px;
  height: auto;
}

.table_content {
  position: relative;
}

.bg-green {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 255, 107, var(--tw-bg-opacity));
}

.rounded-full {
  border-radius: 9999px;
}

.animate-ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.withdrawBtn {
  float: right;
}

.w-2 {
  width: 0.5rem;
}

.h-2 {
  height: 0.5rem;
}

.greenImage {
  height: 20px;
  opacity: 0.5;
}

.harvested {
  margin-left: -10px;
}

.manualCompound {
  font-size: 10px;
}

a.manualCompound {
  text-decoration: none;
}

.moveDown {
  margin-top: 35px;
  margin-bottom: 35px;
}

.lpImage img {
  height: 45px;
  margin-right: 5px;
}

.dexLink {
  font-size: 0.8rem;
  color: #e5e5e5;
  text-decoration: none;
  cursor: pointer;
}

hr {
  margin: 0.5rem 0;
}

.dexLink:hover {
  color: #b6b6b6;
}

.light .card-footer .dexLink {
  color: #333;
}

.light .card-footer .dexLink:hover {
  color: rgb(94, 94, 94);
}

@media only screen and (max-width: 1000px) {
  .bottomSection {
    grid-template-columns: 1fr;
  }

  .poolStatTop {
    display: block;
  }

  .assetInner {
    flex-direction: column;
  }

  .assetLinks {
    justify-content: flex-start;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 770px) {
  .stats {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .mid img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .tokenBadges {
    margin: 5px 0;
    width: 100%;
  }

  .card-foot {
    width: 100%;
    display: block;
    margin: 5px 0;
  }

  .approval button {
    margin-bottom: 45px;
  }

  .poolStatsInner {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .poolStat {
    text-align: left;
  }

  .leftInfoWrapper {
    grid-template-columns: 1fr;
  }
}
</style>
