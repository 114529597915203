<template>
  <footer>
    <div class="fullFooter">
      <div class="container">
        <div class="footerLogo">
          <img src="@/assets/dogepup_dark.svg" alt="DogePup" />
        </div>
        <div class="footerLinks">
          <ul>
            <li>
              <a href="https://twitter.com/PolyPup1" target="_blank"
                ><font-awesome-icon icon="fa-brands fa-twitter"
              /></a>
            </li>
            <li>
              <a href="https://t.me/PolyPupFarm" target="_blank"
                ><font-awesome-icon icon="fa-brands fa-telegram"
              /></a>
            </li>
            <li>
              <a href="https://discord.gg/5y6CF87jfC" target="_blank"
                ><font-awesome-icon icon="fa-brands fa-discord"
              /></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
.fullFooter {
  padding-top: 30px;
  padding-bottom: 40px;
  background-color: #000;
  border-top: none;
  margin-top: 40px;
}

.footerLogo {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 150px;
}

.footerLogo img {
  width: 150px;
}
ul {
  list-style-type: none;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  width: 260px;
  margin-top: 25px;
}

ul li {
  display: block;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  float: left;
}
ul li.last {
  border-right: none;
}

ul li a {
  padding-left: 15px;
}

a {
  color: #fff;
}
</style>
