<template>
  <div class="card">
    <div class="card-header">
      <span class="lpImage"
        ><img :src="require(`@/assets/tokens/${token0.toLowerCase()}.png`)"
      /></span>
      <span class="lpImage" v-if="lp"
        ><img :src="require(`@/assets/tokens/${token1.toLowerCase()}.png`)"
      /></span>
      <span class="tokenTitle">{{ tokenName }}</span>
      <span class="badge bg-secondary allocPoint">{{ allocPoint }}x</span>

      <span class="tokenBadges badge bg-secondary text-light"
        >{{ shortName }}: ${{ formatMoney(tokenPrice) }}</span
      >
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-4">
          <table class="table table_token">
            <tbody class="table_content">
              <tr>
                <th>Earn:</th>
                <td class="small">{{ rewardTokenName }}</td>
              </tr>
              <tr>
                <th>Deposit Fee:</th>
                <td class="small">{{ depositFee }}%</td>
              </tr>
              <tr>
                <th>APR:</th>
                <td class="small">
                  <span class="badge bg-custom aprBadge"
                    >{{ formatBalance(apr, 2) }}%</span
                  >
                  <span class="aprUsd" v-if="userYearlyRewards > 0"
                    >(${{ formatBalance(userYearlyRewards, 2) }})</span
                  >
                </td>
              </tr>
              <tr>
                <th>Daily ROI:</th>
                <td class="small">
                  {{ formatBalance(apr / 365, 2) }}%
                  <span class="aprUsd" v-if="userYearlyRewards > 0"
                    >(${{ formatBalance(userYearlyRewards / 365, 2) }})</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="col-md-4"
          :class="parseInt(allowance) === 0 ? 'approval' : ''"
        >
          <div v-if="parseInt(allowance) > 0" class="midSection">
            <div class="">
              <a
                href=""
                @click.prevent="unstakeToInput(unstaked, `stake${did}`)"
                class="userStaked"
                >{{ unstaked }}</a
              >
              <span class="userStakedPrice"
                >(${{ formatMoney(unstaked * tokenPrice) }})</span
              >
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  :id="`stake${did}`"
                  :placeholder="`Stake ${shortName}`"
                  aria-label=""
                  aria-describedby="button-addon2"
                />
                <button
                  @click="deposit()"
                  class="btn btn-success stk"
                  type="button"
                  id="button-addon2"
                  :disabled="!stakeDisable"
                >
                  <font-awesome-icon icon="fa-solid fa-plus" /> Stake
                </button>
              </div>
            </div>
            <hr />
            <div>
              <a
                @click.prevent="unstakeToInput(staked, `unstake${did}`)"
                class="userStaked"
                href=""
              >
                {{ staked }}
              </a>
              <span class="userStakedPrice"
                >(${{ formatMoney(staked * tokenPrice) }})</span
              >
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  :id="`unstake${did}`"
                  :placeholder="`Unstake ${tokenReplace(shortName)}`"
                  aria-label=""
                  aria-describedby="button-addon2"
                />
                <button
                  @click="withdraw()"
                  class="btn btn-warning stk"
                  type="button"
                  id="button-addon2"
                  :disabled="!unstakeDisable"
                >
                  <font-awesome-icon icon="fa-solid fa-minus" /> Unstake
                </button>
              </div>
            </div>
          </div>
          <div class="approval" v-else>
            <button
              class="btn btn-primary"
              @click="approveContract()"
              :disabled="!approveDisable"
            >
              <span v-if="approveDisable">Approve Contract</span>
              <span v-else
                >Approving <font-awesome-icon icon="fa-solid fa-spinner"
              /></span>
            </button>
          </div>
        </div>
        <div class="col-md-4">
          <div class="harvest-amount">
            <span class="tokenName">Earned</span>
            <span class="tokenAmount">{{
              formatBalance(pendingRewards, 5)
            }}</span>
          </div>

          <hr />

          <div class="harvest-section">
            <span class="tokenName"
              >${{ formatBalance(rewardPrice * pendingRewards, 2) }}</span
            >
            <span class="tokenAmount" v-if="pendingRewards > 0">
              <button
                type="button"
                @click="harvest"
                :disabled="!harvestDisable"
                class="btn btn-primary"
              >
                <span v-if="harvestDisable"> Harvest </span>
                <span v-else>
                  Harvest
                  <font-awesome-icon icon="fa-solid fa-spinner" />
                </span>
              </button>
            </span>
            <span class="tokenAmount" v-else>
              <button type="button" class="disabled btn btn-primary">
                Harvest
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <small class="text-muted card-foot"
        ><a
          class="dexLink"
          :href="`https://explorer.dogechain.dog/address/${stakeToken}`"
          rel="noreferrer"
          target="_blank"
          >TVL: ${{ formatMoney(staked_tvl * tokenPrice) }}
          <font-awesome-icon icon="fa-solid fa-square-arrow-up-right"
        /></a>
      </small>
      <small class="text-muted card-foot" v-if="lp">
        <a
          class="dexLink"
          :href="`${liquidityAdd}`"
          rel="noreferrer"
          target="_blank"
        >
          Add Liquidity
          <font-awesome-icon icon="fa-solid fa-square-arrow-up-right" /></a
      ></small>
      <small class="text-muted card-foot" v-if="lp">
        <a
          class="dexLink"
          :href="`${liquidityRemove}`"
          rel="noreferrer"
          target="_blank"
        >
          Remove Liquidity
          <font-awesome-icon icon="fa-solid fa-square-arrow-up-right" /></a
      ></small>
      <small class="text-muted card-foot">
        <a
          class="dexLink"
          :href="`${buyToken0}`"
          rel="noreferrer"
          target="_blank"
        >
          Buy {{ token0Name }}
          <font-awesome-icon icon="fa-solid fa-square-arrow-up-right" /></a
      ></small>
      <small class="text-muted card-foot" v-if="lp">
        <a
          class="dexLink"
          :href="`${buyToken1}`"
          rel="noreferrer"
          target="_blank"
        >
          Buy {{ token1Name }}
          <font-awesome-icon icon="fa-solid fa-square-arrow-up-right" /></a
      ></small>
    </div>
  </div>
</template>

<script>
import { createToast } from "mosha-vue-toastify";
import Web3 from "web3";
import ERC20 from "../../abi/ERC20.json";
import MasterChef from "../../abi/masterChef.json";

export default {
  props: {
    did: Number,
    tag: String,
    lp: Boolean,
    allocPoint: Number,
    depositFee: Number,
    tokenName: String,
    shortName: String,
    stakeToken: String,
    stakeTokenDecimals: Number,
    rewardTokenName: String,
    rewardToken: String,
    masterchef: String,
    pid: Number,
    token0: String,
    token0Name: String,
    token0Decimals: Number,
    token1: String,
    token1Name: String,
    token1Decimals: Number,
    liquidityAdd: String,
    liquidityRemove: String,
    buyToken0: String,
    buyToken1: String,
    blockString: String,
    pendingString: String,
    perSecond: Boolean,
  },
  emits: [
    "updateTVL",
    "updateUserVaults",
    "updatePending",
    "updateStakedValue",
  ],
  components: {
    //ApyModal,
  },
  data() {
    return {
      gwei: "75",
      unstakeIcon: "fa-minus",
      stakeIcon: "fa-plus",
      unstakeDisable: true,
      stakeDisable: true,
      harvestDisable: true,
      approveDisable: true,
      increment: 30,
      staked_tvl: 0,
      staked_tvl_amount: 0,
      allowance: 0,
      pendingRewards: 0,
      staked: 0,
      unstaked: 0,
      yearlyRewards: 83011.4968,
      currentDifference: 0,
      tokenPrice: 0,
      rewardPrice: 0,
      poolTVL: 0,
      apy: 0,
      apr: 0,
      userYearlyRewards: 0,
      rpc: "https://rpc.ankr.com/dogechain/",
      //rpc: "http://34.239.187.50/rpc",
    };
  },
  async mounted() {
    await this.sleep(1000);
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", async () => {
        await this.sleep(1000);
        this.fetchPool();
      });

      window.ethereum.on("chainChanged", async () => {
        await this.sleep(1000);
        this.fetchPool();
      });
    }

    await this.stakeTokenPrice();
    await this.fetchPool();
    await this.calculateAPR();
    this.calculateTime();

    this.getTVL();
    this.getUserStaked();

    this.getUserVault();

    this.pollInterval = setInterval(async () => {
      if (this.increment < 1 && !this.load) {
        console.log("loading vault...");
        this.load = true;
        await this.fetchPool();
        this.calculateTime();
        this.getUserStaked();
        this.increment = 30;
        this.load = false;
      } else {
        if (!this.load) {
          this.increment = this.increment - 1;
        }
      }
    }, 1000);
  },
  beforeUnmount() {
    this.stop = true;
    clearInterval(this.pollInterval);
  },
  computed: {
    isDisabled: function () {
      return !this.unstakeDisable;
    },
  },
  methods: {
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    getUserStaked() {
      const userStake = this.staked * this.tokenPrice;
      this.$emit("updateStakedValue", this.did, userStake);
    },
    getTVL() {
      const tvl = this.staked_tvl * this.tokenPrice;
      this.$emit("updateTVL", this.did, tvl);
    },
    getUserVault() {
      if (this.staked > 0) {
        this.$emit("updateUserVaults", this.did, "add");
      } else {
        this.$emit("updateUserVaults", this.did, "remove");
      }
    },
    calculateTime() {
      this.currentDifference = new Date(Date.now() - this.harvestedTime * 1000);
    },
    async stakeTokenPrice() {
      if (!this.lp) {
        this.tokenPrice = await this.fetchPrice(this.token0);
        return;
      }
      const web3 = new Web3(new Web3.providers.HttpProvider(this.rpc));

      const t0 = new web3.eth.Contract(ERC20, this.token0);
      const t1 = new web3.eth.Contract(ERC20, this.token1);
      const lp = new web3.eth.Contract(ERC20, this.stakeToken);
      const p0 = await this.fetchPrice(this.token0);
      const p1 = await this.fetchPrice(this.token1);

      let q0, q1, total_supply;

      [q0, q1, total_supply] = await this.makeBatchRequest(
        [
          t0.methods.balanceOf(this.stakeToken).call,
          t1.methods.balanceOf(this.stakeToken).call,
          lp.methods.totalSupply().call,
        ],
        web3
      );

      q0 = q0 / 10 ** this.token0Decimals;
      q1 = q1 / 10 ** this.token1Decimals;
      total_supply = total_supply / 10 ** this.stakeTokenDecimals;
      const tvl = q0 * p0 + q1 * p1;

      this.poolTVL = tvl;

      this.tokenPrice = tvl / total_supply;
    },
    async fetchPrice(address) {
      return new Promise((resolve) => {
        fetch(
          `https://api.polypup.finance/v1/price?network=doge&address=${address}`
        )
          .then((res) => res.json())
          .then((data) => {
            if (data.status == "ok") {
              resolve(data.price);
            }
          });
      });
    },
    lpTotals(tvl, lpPrice, userStaked, tokenPrice) {
      let totalSupply = tvl / lpPrice;
      let userPercent = userStaked / totalSupply;
      return this.formatBalance(userPercent * tokenPrice);
    },
    makeBatchRequest(calls, web3) {
      let batch = new web3.BatchRequest();

      let promises = calls.map((call) => {
        return new Promise((res, rej) => {
          let req = call.request((err, data) => {
            if (err) rej(err);
            else res(data);
          });
          batch.add(req);
        });
      });
      batch.execute();

      return Promise.all(promises);
    },
    async calculateAPR() {
      //const compoundingPeriods = 365;
      const web3 = new Web3(new Web3.providers.HttpProvider(this.rpc));
      const chef = new web3.eth.Contract(MasterChef, this.masterchef);
      let pool, totalAllocPoint, perBlock;

      [pool, totalAllocPoint, perBlock] = await this.makeBatchRequest(
        [
          chef.methods.poolInfo(this.pid).call,
          chef.methods.totalAllocPoint().call,
          chef.methods[this.blockString]().call,
        ],
        web3
      );

      perBlock = web3.utils.fromWei(perBlock.toString(), "ether");

      let tokensPerYear = this.perSecond
        ? 525600 * 60 * perBlock
        : (525600 * 60 * perBlock) / 2;

      let allocPoint = pool.allocPoint;
      let poolPercentage = allocPoint / totalAllocPoint;
      let poolTokens = tokensPerYear * poolPercentage;
      let tokenPrice = await this.fetchPrice(this.rewardToken);
      this.rewardPrice = tokenPrice;
      let rewardsPerYear = poolTokens * tokenPrice;
      let staked_tvl_amount = this.staked_tvl_amount * this.tokenPrice;

      let userStake = this.staked * this.tokenPrice;

      let userStakedPct = (userStake / staked_tvl_amount) * 100;
      this.userYearlyRewards =
        (userStakedPct * (poolTokens * tokenPrice)) / 100;

      let apr = rewardsPerYear / staked_tvl_amount;

      this.apr = apr * 100;
    },
    async fetchPool() {
      let address = this.$store.state.web3.coinbase
        ? this.$store.state.web3.coinbase
        : "0x2ddb73bdC6b086D4959D4Af87C0Ff05438dD66a7";

      // if (window.ethereum) {
      const web3 = new Web3(new Web3.providers.HttpProvider(this.rpc));

      let stakeTokenContract = new web3.eth.Contract(ERC20, this.stakeToken);

      let stakingContract = new web3.eth.Contract(MasterChef, this.masterchef);

      let allowance, balanceUnstaked, userInfo, staked_tvl, pendingRewards;

      [allowance, balanceUnstaked, userInfo, staked_tvl, pendingRewards] =
        await this.makeBatchRequest(
          [
            stakeTokenContract.methods.allowance(address, this.masterchef).call,
            stakeTokenContract.methods.balanceOf(address).call,
            stakingContract.methods.userInfo(this.pid, address).call,
            stakeTokenContract.methods.balanceOf(this.masterchef).call,
            stakingContract.methods[this.pendingString](this.pid, address).call,
          ],
          web3
        );

      this.allowance = allowance;
      this.unstaked = this.convertWeiToEther(
        balanceUnstaked,
        this.stakeTokenDecimals
      );

      this.staked = this.convertWeiToEther(
        userInfo.amount,
        this.stakeTokenDecimals
      );

      let pendingBefore = this.convertWeiToEther(pendingRewards, 18);

      let pendingRewardsObject = [{ id: this.did, pending: pendingBefore }];

      // this.pendingRewards = pendingBefore;

      this.pendingRewards = 0;

      this.$emit("updatePending", pendingRewardsObject);

      this.staked_tvl = this.convertWeiToEther(
        staked_tvl,
        this.stakeTokenDecimals
      );

      this.staked_tvl_amount = this.staked_tvl;
    },
    formatMoney(input) {
      let decimals;
      if (input < 0.1) {
        decimals = 5;
      } else {
        decimals = 2;
      }
      return Number(input).toLocaleString("en-US", {
        maximumFractionDigits: decimals,
      });
    },
    formatBalance: (amount, decimal) => {
      return Number(amount).toLocaleString("en-US", {
        maximumFractionDigits: decimal,
      });
    },
    tokenReplace(string) {
      string = string.replace("[", "");
      string = string.replace("[", "");
      string = string.replace("]", "");
      string = string.replace("]", "");
      return string.toUpperCase();
    },
    formatAllocPoints(data) {
      return `${parseInt(data) / 100}x`;
    },
    unstakeToInput(val, id) {
      document.getElementById(id).value = val;
    },
    convertEtherToWei(value, decimals) {
      if (decimals < 18) {
        return value * 10 ** decimals;
      } else {
        let web3 = new Web3(window.ethereum);
        return web3.utils.toWei(value, "ether");
      }
    },

    convertWeiToEther(value, decimals) {
      if (decimals < 18) {
        return value / 10 ** decimals;
      } else {
        let web3 = new Web3(window.ethereum);
        return web3.utils.fromWei(value.toString(), "ether");
      }
    },

    async deposit() {
      let web3 = new Web3(window.ethereum);
      let gasEstimated = await web3.eth.getGasPrice();

      gasEstimated = web3.utils
        .toBN(gasEstimated)
        .mul(web3.utils.toBN(13))
        .div(web3.utils.toBN(10));

      let gasGwei = web3.utils.fromWei(gasEstimated, "gwei");

      gasGwei = Math.ceil(gasGwei);

      let gasSpeed = localStorage.gwei
        ? gasGwei > localStorage.gwei
          ? gasGwei
          : localStorage.gwei.toString()
        : gasGwei.toString();
      this.stakeIcon = "fa-spin fa-spinner";
      this.stakeDisable = false;
      let newvalue = 0;
      let value = document.getElementById(`stake${this.did}`).value;

      if (!value) {
        this.stakeIcon = "fa-plus";
        this.stakeDisable = true;
        createToast(`You must enter a value!`, {
          toastBackgroundColor: "red",
          transition: "bounce",
          type: "success",
        });
        return;
      }

      newvalue = this.convertEtherToWei(value, this.stakeTokenDecimals);

      let chefContract = new web3.eth.Contract(MasterChef, this.masterchef);
      chefContract.methods
        .deposit(this.pid, newvalue.toString())
        .send({
          from: this.$store.state.web3.coinbase,
          gasPrice: web3.utils.toWei(gasSpeed.toString(), "gwei"),
        })
        .then(() => {
          this.fetchPool();
          document.getElementById(`stake${this.did}`).value = "";
          this.stakeDisable = true;
          this.stakeIcon = "fa-plus";
          createToast(`Transaction Success!`, {
            showIcon: true,
            toastBackgroundColor: "green",
            transition: "bounce",
            type: "success",
          });
        })
        .catch((e) => {
          this.stakeDisable = true;
          this.stakeIcon = "fa-plus";
          createToast(`Metamask error, try again...`, {
            showIcon: true,
            toastBackgroundColor: "red",
            transition: "bounce",
            type: "error",
          });
          console.log(e);
        });
    },
    async withdraw() {
      this.unstakeIcon = "fa-spin fa-spinner";
      this.unstakeDisable = false;
      let newvalue = 0;
      let value = document.getElementById(`unstake${this.did}`).value;

      if (!value) {
        this.unstakeIcon = "fa-minus";
        this.unstakeDisable = true;
        createToast(`You must enter a value!`, {
          toastBackgroundColor: "red",
          transition: "bounce",
          type: "success",
        });
        return;
      }

      newvalue = this.convertEtherToWei(value, this.stakeTokenDecimals);

      if (newvalue > 0) {
        let web3 = new Web3(window.ethereum);
        let gasEstimated = await web3.eth.getGasPrice();

        gasEstimated = web3.utils
          .toBN(gasEstimated)
          .mul(web3.utils.toBN(13))
          .div(web3.utils.toBN(10));

        let gasGwei = web3.utils.fromWei(gasEstimated, "gwei");

        gasGwei = Math.ceil(gasGwei);

        let gasSpeed = localStorage.gwei
          ? gasGwei > localStorage.gwei
            ? gasGwei
            : localStorage.gwei.toString()
          : gasGwei.toString();
        let chefContract = new web3.eth.Contract(MasterChef, this.masterchef);
        chefContract.methods
          .withdraw(this.pid, newvalue.toString())
          .send({
            from: this.$store.state.web3.coinbase,
            gasPrice: web3.utils.toWei(gasSpeed.toString(), "gwei"),
          })
          .then(() => {
            this.fetchPool();
            document.getElementById(`unstake${this.did}`).value = "";
            this.unstakeDisable = true;
            this.unstakeIcon = "fa-minus";
            createToast(`Transaction Success`, {
              showIcon: true,
              toastBackgroundColor: "green",
              transition: "bounce",
              type: "success",
            });
          })
          .catch((e) => {
            this.unstakeDisable = true;
            this.unstakeIcon = "fa-minus";
            createToast(`Metamask error, try again...`, {
              showIcon: true,
              toastBackgroundColor: "red",
              transition: "bounce",
              type: "error",
            });
            console.log(e);
          });
      } else {
        this.unstakeDisable = true;
        this.unstakeIcon = "fa-minus";
        document.getElementById(`unstake${this.did}`).value = "";
        createToast(`Metamask error, try again...`, {
          toastBackgroundColor: "red",
          transition: "bounce",
          type: "success",
        });
      }
    },

    async harvest() {
      this.harvestDisable = false;

      let web3 = new Web3(window.ethereum);
      let gasEstimated = await web3.eth.getGasPrice();

      gasEstimated = web3.utils
        .toBN(gasEstimated)
        .mul(web3.utils.toBN(13))
        .div(web3.utils.toBN(10));

      let gasGwei = web3.utils.fromWei(gasEstimated, "gwei");

      gasGwei = Math.ceil(gasGwei);

      let gasSpeed = localStorage.gwei
        ? gasGwei > localStorage.gwei
          ? gasGwei
          : localStorage.gwei.toString()
        : gasGwei.toString();
      let chefContract = new web3.eth.Contract(MasterChef, this.masterchef);

      chefContract.methods
        .deposit(this.pid, 0)
        .send({
          from: this.$store.state.web3.coinbase,
          gasPrice: web3.utils.toWei(gasSpeed.toString(), "gwei"),
        })
        .then(() => {
          this.fetchPool();
          this.harvestDisable = true;
          createToast(`Transaction Success`, {
            showIcon: true,
            toastBackgroundColor: "green",
            transition: "bounce",
            type: "success",
          });
        })
        .catch((e) => {
          this.harvestDisable = true;
          createToast(`Metamask error, try again...`, {
            showIcon: true,
            toastBackgroundColor: "red",
            transition: "bounce",
            type: "error",
          });
          console.log(e);
        });
    },

    async approveContract() {
      this.approveDisable = false;
      let amount =
        "115792089237316195423570985008687907853269984665640564039457584007913129639935";
      let address = this.$store.state.web3.coinbase;
      let tokenContract = this.stakeToken;
      let masterchefContract = this.masterchef;
      if (window.ethereum) {
        if (address && tokenContract && masterchefContract) {
          let web3 = new Web3(window.ethereum);
          let gasEstimated = await web3.eth.getGasPrice();

          gasEstimated = web3.utils
            .toBN(gasEstimated)
            .mul(web3.utils.toBN(13))
            .div(web3.utils.toBN(10));

          let gasGwei = web3.utils.fromWei(gasEstimated, "gwei");

          gasGwei = Math.ceil(gasGwei);

          let gasSpeed = localStorage.gwei
            ? gasGwei > localStorage.gwei
              ? gasGwei
              : localStorage.gwei.toString()
            : gasGwei.toString();
          let approve = new web3.eth.Contract(ERC20, tokenContract);
          approve.methods
            .approve(masterchefContract, amount)
            .send({
              from: this.$store.state.web3.coinbase,
              gasPrice: web3.utils.toWei(gasSpeed.toString(), "gwei"),
            })
            .then(() => {
              this.fetchPool();
              this.approveDisable = true;
              createToast(`Transaction Success`, {
                showIcon: true,
                toastBackgroundColor: "green",
                transition: "bounce",
                type: "success",
              });
            })
            .catch((e) => {
              this.approveDisable = true;
              createToast(`Metamask error, try again...`, {
                showIcon: true,
                toastBackgroundColor: "red",
                transition: "bounce",
                type: "error",
              });
              console.log(e);
            });
        } else {
          this.approveDisable = true;
          createToast(`No address, token contract or masterchef detected...`, {
            toastBackgroundColor: "red",
            transition: "bounce",
            type: "success",
          });
        }
      } else {
        this.approveDisable = true;
        createToast(`No metamask detected...`, {
          toastBackgroundColor: "red",
          transition: "bounce",
          type: "success",
        });
      }
    },
  },
};
</script>

<style scoped>
.dark .modal-content {
  background-color: #1b2443;
}

.dark .btn-close {
  color: #fff;
}
.card-foot {
  padding-right: 25px;
}
.dark .approval {
  background: #2f3d75;
}
.approval {
  background: #f7faff;
  border-radius: 5px;
}
.approval .btn {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 55px;
}
.harvest-amount {
  display: block;
  padding-bottom: 15px;
  padding-top: 15px;
  height: 56px;
}

.allocPoint {
  margin-left: 10px;
  font-size: 10px;
  font-weight: 500;
}

.btnFooter {
  font-size: 0.675rem;
}
.harvest-amount .tokenName {
  float: left;
  font-weight: bold;
  padding-top: 10px;
  padding-left: 20px;
  font-size: 20px;
  text-transform: uppercase;
}

.harvest-amount .tokenAmount {
  float: right;
  padding-right: 20px;
  padding-top: 10px;
  font-size: 20px;
}

.harvest-section .tokenName {
  float: left;
  font-weight: bold;
  padding-left: 20px;
  font-size: 26px;
}

.stakedBackground {
  border-left: green solid 6px;
}

.harvest-section .tokenAmount {
  float: right;
  padding-right: 20px;
}

.aprUsd {
  float: right;
  font-size: 10px;
  color: green;
}

.midSection {
  margin-bottom: 25px;
}

.table_content th {
  font-weight: 200;
}

.aprPercent {
  float: left;
}

.tokenTitle {
  text-transform: uppercase;
}

.tokenBadges {
  float: right;
  margin-right: 10px;
}

.form-control {
  font-size: 12px;
}

.strong {
  font-weight: bold;
}

.totalValue {
  display: block;
  text-align: center;
  font-size: 0.9rem;
}

.dark .aprUsd {
  color: #42ff75;
}

.ended {
  color: #fff;
  margin-left: 10px;
  border-radius: 5px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.yodeswap {
  background-color: #6b4800;
}

.kibbleswap {
  background-color: #70016b;
}

.dark .card-footer .text-muted {
  color: #d1d1d1 !important;
}

.dark .card-footer {
  background: rgb(47, 61, 117);
}

.dark .userStaked {
  color: #ccddfc;
}

.userStaked {
  float: left;
  font-size: 12px;
  color: #333333;
}

.stk {
  width: 100px;
  font-size: 12px;
}

.dark .userStakedPrice {
  color: #ccddfc;
}

.userStakedPrice {
  float: right;
  font-size: 12px;
  color: #333333;
}

.table {
  width: 100%;
}

.table tr th {
  min-width: 100px;
}

h4.card-title {
  color: #333333;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
/* This is the class name given by the Font Awesome component when icon contains 'spinner' */
.fa-spinner {
  /* Apply 'spinner' keyframes looping once every second (1s)  */
  animation: spinner 1s linear infinite;
}

.table_token {
  overflow: hidden;
  position: relative;
}

.table_bg {
  opacity: 0.2;
  position: absolute;
  left: 0;
  top: 0px;
  width: 200px;
  height: auto;
}

.table_content {
  position: relative;
}

.bg-green {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 255, 107, var(--tw-bg-opacity));
}

.rounded-full {
  border-radius: 9999px;
}

.animate-ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.w-2 {
  width: 0.5rem;
}
.h-2 {
  height: 0.5rem;
}

.greenImage {
  height: 20px;
  opacity: 0.5;
}
.aprBadge {
  font-size: 0.85rem;
}

.harvested {
  margin-left: -10px;
}

.manualCompound {
  font-size: 10px;
}

a.manualCompound {
  text-decoration: none;
}

.moveDown {
  margin-top: 35px;
  margin-bottom: 35px;
}

.lpImage img {
  height: 30px;
  margin-right: 5px;
}

.table_content span.bg-custom {
  background-color: #7f5200;
}

.dexLink {
  font-size: 0.8rem;
  color: #e5e5e5;
  text-decoration: none;
}

.dexLink:hover {
  color: #b6b6b6;
}

.light .card-footer .dexLink {
  color: #333;
}

.light .card-footer .dexLink:hover {
  color: rgb(94, 94, 94);
}
</style>
