import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import App from "./App.vue";
import router from "./router";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { faGears } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faSquareArrowUpRight } from "@fortawesome/free-solid-svg-icons";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faRocket } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import moshaToast from "mosha-vue-toastify";
import moment from "moment";
import "mosha-vue-toastify/dist/style.css";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

library.add(
  faCircleInfo,
  faWallet,
  faTwitter,
  faGears,
  faTelegram,
  faCalculator,
  faDiscord,
  faBars,
  faPlus,
  faMinus,
  faSpinner,
  faSquareArrowUpRight,
  faRocket,
  faCopy,
  faSort
);

const app = createApp(App);

app.config.globalProperties.$filters = {
  timeAgo(difference) {
    let current = Date.now() - difference;

    return moment(current).fromNow();
  },
};

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app
  .use(router)
  .use(pinia)
  .use(moshaToast)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
