<script setup>
import { useStore } from "./store";
const store = useStore();
store.initialize();
</script>

<template>
  <div class="app" :class="mode === 'dark' ? 'dark' : 'light'">
    <Header :mode="mode" @changeModeFinal="changeModeFinal" />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";

export default {
  name: "App",
  emits: ["changeModeFinal"],
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      mode: "dark",
    };
  },
  methods: {
    changeModeFinal(mode) {
      if (mode === "dark") {
        localStorage.darkMode = "light";
        document.body.classList.add("light");
        document.body.classList.remove("dark");
        this.mode = "light";
      } else {
        localStorage.darkMode = "dark";
        document.body.classList.add("dark");
        document.body.classList.remove("light");
        this.mode = "dark";
      }
    },
  },
  mounted() {
    if (localStorage.darkMode) {
      this.mode = localStorage.darkMode;
    }
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
  font-size: 1em;
  line-height: 1.5;
  font-family: "DM Sans", -apple-system, system-ui, BlinkMacSystemFont,
    "Helvetica Neue", "Helvetica", sans-serif;
  background-color: #08091c;
  min-height: 100vh;
}

.dark {
  background: #08080b;
  color: #f3f3f3;
}

.light {
  background: #eceff6;
  color: #253646;
}

.dark .green {
  color: #42ff75;
}

.dark header,
.dark footer,
.dark .fullWhite {
  background: #1b2443;
  color: #efefef;
}

.dark span.pending {
  background: #08091c;
}

.dark header .navbar-light .navbar-nav .nav-link {
  color: #efefef;
}

.dark .table .ad_color {
  background: #08091c;
}

.dark table {
  color: #efefef;
}

.dark .table-striped > tbody > tr:nth-of-type(odd) {
  color: #fff;
}
.dark footer .fullWhite ul li a {
  color: #fff;
}

.dark footer .fullWhite ul li {
  border-right: 1px solid #08091c;
}

.dark footer .fullWhite ul li.last {
  border-right: none;
}
.dark .card-header {
  background: #2f3d75;
}

.dark .table tr td a {
  color: #fff;
}

.dark table tr {
  border-bottom: 1px solid #2f3d75;
}

.autopup {
  background-color: #eceff6;
}

.mh-logo {
  margin: 0 0 -10px;
}
.main-nav a {
  font-size: 0.75em;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #343534;
  text-decoration: none;
}
.main-nav ul {
  margin: 0;
  padding: 0;
}

.center {
  margin-top: 4em;
  text-align: center;
}
.center a {
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.1em;
  color: #f34a4e;
  border-bottom: 1px solid #f34a4e;
  transition: all 0.275s;
}
.center a:hover,
.center a:focus {
  opacity: 0.8;
  padding-bottom: 2px;
  border-bottom-color: rgba(243, 74, 95, 0.5);
}

img {
  max-width: 100%;
  height: auto;
}
.container {
  width: 95%;
  max-width: 1460px;
  padding: 0;
  margin: 0 auto;
}
.main-header .container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
}
.main-nav ul {
  margin: 1em 0 0.5em;
  text-align: center;
}
.main-nav li {
  display: inline;
}
.main-nav a {
  display: inline-block;
  padding: 0.5em 1.5em;
}

@media (min-width: 960px) {
  .main-header .container {
    justify-content: space-between;
  }
}
</style>
