<template>
  <div class="container">
    <div class="row farms">
      <div class="col-md-12 order-sm-first order-md-last">
        <TopSectionVaults :burned="burned" :active="pools.length" />

        <div class="poolGroup">
          <div class="poolGroupHeader">
            <div class="poolHeaderCol1">
              <input
                placeholder="Search..."
                type="text"
                class="poolHeaderSearch"
                v-model="searchTokens"
                @input="searchFunction"
              />
            </div>
            <div class="poolHeaderOther">
              <div class="poolHeaderCol2">Wallet</div>
              <div class="poolHeaderCol2">Deposited</div>
              <div class="poolHeaderCol2">
                APY
                <font-awesome-icon
                  icon="fa-solid fa-sort"
                  @click="sorted = 'apy'"
                />
              </div>
              <div class="poolHeaderCol2">Earned</div>
              <div class="poolHeaderCol2">Pending</div>
              <div class="poolHeaderCol2">
                TVL
                <font-awesome-icon
                  icon="fa-solid fa-sort"
                  @click="sorted = 'tvl'"
                />
              </div>
            </div>
            <div class="poolHeaderDropdown">
              <div class="selectOuter">
                <div class="selectInner">
                  <div class="selectSort">Sort By:</div>
                  <select class="selectorSort" @change="sortDropdown($event)">
                    <option value="apy">APY</option>
                    <option value="tvl">TVL</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div v-if="pools.length > 0">
            <div v-for="pool in filteredVaults" :key="pool.id">
              <Vault
                :chain_image="pool.chain_image"
                :chain_id="pool.chain_id"
                :rpc="pool.rpc"
                :did="pool.id"
                :dex="pool.dex"
                :volume="pool.volume"
                :lpfee="pool.lpfee"
                :dexLink="pool.dexLink"
                :contract="pool.contract"
                :image="pool.image"
                :stakeTokenName="pool.stakeTokenName"
                :shortName="pool.shortName"
                :stakeToken="pool.stakeToken"
                :stakeTokenDecimals="pool.stakeTokenDecimals"
                :rewardTokenName="pool.rewardTokenName"
                :rewardToken="pool.rewardToken"
                :burnTokenName="pool.burnTokenName"
                :burnToken="pool.burnToken"
                :router="pool.router"
                :masterchef="pool.masterchef"
                :pid="pool.pid"
                :weth="pool.weth"
                :token0="pool.token0"
                :token0Name="pool.token0Name"
                :token0Decimals="pool.token0Decimals"
                :token1="pool.token1"
                :token1Name="pool.token1Name"
                :token1Decimals="pool.token1Decimals"
                :tokensPerYear="pool.tokensPerYear"
                :liquidityAdd="pool.liquidityAdd"
                :liquidityRemove="pool.liquidityRemove"
                :blockString="pool.blockString"
                :perSecond="pool.perSecond"
                :abi="pool.chefABI"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vault from "../components/vaults/Vault.vue";
import Vaults from "../abi/vaults.json";
import Closed from "../abi/closed.json";
import ERC20 from "../abi/ERC20.json";
import TopSectionVaults from "../components/vaults/TopSectionVaults.vue";
import { useRoute } from "vue-router";
import Web3 from "web3";
import { storeToRefs } from "pinia";
import { useStore } from "../store";
export default {
  setup() {
    const store = useStore();
    const {
      chains,
      chainId,
      filterType,
      filterSecondary,
      filterThird,
      userVaults,
      dogepup,
      doge,
    } = storeToRefs(store);
    return {
      store,
      chains,
      chainId,
      filterType,
      filterSecondary,
      filterThird,
      userVaults,
      dogepup,
      doge,
    };
  },
  watch: {
    chainId() {
      console.log("chainId", this.chainId);
      //this.activeChain = this.chainId;
    },
    chains() {
      console.log(this.chains);
      //this.activeChain = this.chainId;
    },
  },
  components: {
    Vault,
    TopSectionVaults,
  },
  data() {
    return {
      filterFirstCount: 0,
      filterSecondCount: 0,
      filterThirdCount: 0,
      sorted: null,
      burned: 0,
      pools: Vaults.pools,
      closed: Closed.pools,
      contract: "0x1b15b9446b9f632a78396a1680DAaE17f74Ce8d9",
      searchTokens: null,
      q: null,
      query: false,
      rpc: "https://rpc.ankr.com/dogechain",
      refreshTimer: null,
      sortOpen: true,
    };
  },
  computed: {
    filteredVaults() {
      if (this.filterType || this.sorted != null) {
        return this.filterPool();
      } else {
        return this.filterPool().sort((a, b) => {
          if (a.importance < b.importance) return -1;
          if (a.importance > b.importance) return 1;
          return 0;
        });
      }
    },
  },
  methods: {
    sortDropdown(event) {
      this.sorted = event.target.value;
    },
    searchFunction(event) {
      this.searchTokens = event.target.value;
      this.q = "";
      this.query = false;
    },
    getFilterType() {
      if (this.filterType == "my") {
        this.filterFirstCount = 1;
        return this.pools.filter((pool) => this.userVaults.includes(pool.id));
      } else if (this.filterType == "retired") {
        this.filterFirstCount = 1;
        return this.closed.filter((pool) => pool.status == "inactive");
      } else {
        this.filterFirstCount = 0;
        return this.pools.filter((pool) => pool.status == "active");
      }
    },
    getFilterSecondType(pools) {
      if (this.filterSecondary == "new") {
        this.filterSecondCount = 1;
        const length = pools.length;
        const lastId = length - 5;
        return pools.filter((pool) => pool.id > lastId);
      } else if (this.filterSecondary == "stable") {
        this.filterSecondCount = 1;
        return pools.filter((pool) => pool.tag.includes("stable"));
      } else if (this.filterSecondary == "featured") {
        this.filterSecondCount = 1;
        return pools.filter((pool) => pool.tag.includes("featured"));
      } else {
        this.filterSecondCount = 0;
        return pools;
      }
    },
    getFilterThirdType(pools) {
      if (this.filterThird == "lp") {
        this.filterThirdCount = 1;
        return pools.filter((pool) => pool.asset_type == "lp");
      } else if (this.filterThird == "single") {
        this.filterThirdCount = 1;
        return pools.filter((pool) => pool.asset_type == "single");
      } else {
        this.filterThirdCount = 0;
        return pools;
      }
    },
    getFilterSearch(pools) {
      if (this.searchTokens && this.searchTokens.length >= 3) {
        return pools.filter(
          (pool) =>
            pool.stakeTokenName
              .toLowerCase()
              .includes(this.searchTokens.toLowerCase()) ||
            pool.dex.toLowerCase().includes(this.searchTokens.toLowerCase()) ||
            pool.farm.toLowerCase().includes(this.searchTokens.toLowerCase())
        );
      } else {
        return pools;
      }
    },
    getSortBy(type, pools) {
      if (type && type.length > 0) {
        console.log(`sorting by ${type}`);
        return pools.sort((a, b) => {
          if (parseFloat(a[type]) > parseFloat(b[type])) return -1;
          if (parseFloat(a[type]) < parseFloat(b[type])) return 1;
          return 0;
        });
      } else {
        return pools;
      }
    },
    async searchFilter(data) {
      console.log(`query: ${data}`);
    },
    filterPool() {
      if (this.q && this.q.length >= 2) {
        return this.pools.filter(
          (pool) =>
            pool.stakeTokenName.toLowerCase().includes(this.q.toLowerCase()) ||
            pool.dex.toLowerCase().includes(this.q.toLowerCase()) ||
            pool.farm.toLowerCase().includes(this.q.toLowerCase())
        );
      }

      let pools = this.getFilterType();
      pools = this.getFilterSecondType(pools);
      pools = this.getFilterThirdType(pools);
      pools = this.getFilterSearch(pools);
      console.log(this.sorted);
      pools = this.getSortBy(this.sorted, pools);

      console.log(pools);

      const filterCount =
        this.filterFirstCount + this.filterSecondCount + this.filterThirdCount;
      this.store.updateFilterCount(filterCount);
      return pools;
    },
    async getBurned() {
      const web3 = new Web3(new Web3.providers.HttpProvider(this.rpc));

      let tokenContract = new web3.eth.Contract(ERC20, this.contract);
      const burned = await tokenContract.methods
        .balanceOf("0x000000000000000000000000000000000000dEaD")
        .call();

      this.burned = parseFloat(web3.utils.fromWei(burned, "ether"));
    },
    async setPrices() {
      const doge = await this.fetchPrice(
        "0xB7ddC6414bf4F5515b52D8BdD69973Ae205ff101"
      );
      const dogepup = await this.fetchPrice(
        "0x1b15b9446b9f632a78396a1680DAaE17f74Ce8d9"
      );
      this.store.updateDogePup(dogepup);
      this.store.updateDoge(doge);
    },
    async fetchPrice(address) {
      return new Promise((resolve) => {
        fetch(
          `https://api.polypup.finance/v1/price?network=doge&address=${address}`
        )
          .then((res) => res.json())
          .then((data) => {
            if (data.status == "ok") {
              resolve(data.price);
            }
          });
      });
    },
    async updateValues() {
      this.refreshTimer = setTimeout(async () => {
        await this.getBurned();
        await this.setPrices();
        await this.updateValues();
      }, 30000);
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
  },
  async mounted() {
    const route = useRoute();
    if (route.query["q"]) {
      this.q = route.query["q"];
      this.query = true;
      this.$router.replace({ query: null });
    }

    await this.getBurned();
    await this.setPrices();
    await this.updateValues();
  },
  unmounted() {
    clearTimeout(this.refreshTimer);
  },
};
</script>

<style>
.poolGroup {
  /*
  363b63
  2d3153
  */
  border: solid 2px #363b63;
  margin-top: 32px;
  border-radius: 12px;
  background: #2d3153;
}

.poolGroupHeader {
  grid-template-columns: minmax(0, 30fr) minmax(0, 70fr);
  color: #9595b2;
  width: 100%;
  display: grid;
  padding: 24px;
  row-gap: 16px;
  background: #2d3153;
  column-gap: 24px;
  align-items: center;
  border-bottom: solid 2px #363b63;
  background-clip: padding-box;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.poolHeaderSearch {
  color: #d0d0da;
  background: #1b1e31;
  border-radius: 8px;
  width: 100%;
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 16px;
  box-sizing: border-box;
  align-items: center;
  font-weight: 400;
  line-height: 1.1876em;
  text-transform: none;
}

.poolHeaderCol1 {
  color: #d0d0da;
  background: #1b1e31;
  border-radius: 8px;
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 16px;
  box-sizing: border-box;
  align-items: center;
  font-family: "DM Sans", system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  line-height: 1.1876em;
  text-transform: none;
}

.poolHeaderCol1 input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  font-size: 16px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  font-weight: 500;
  line-height: 24px;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  text-transform: none;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
  color: #d0d0da;
  height: auto;
  padding: 6px 16px;
}

.poolHeaderOther {
  width: 100%;
  display: grid;
  column-gap: 24px;
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.poolHeaderCol2 {
  text-align: right;
  font-size: 0.85rem;
}

.poolHeaderDropdown {
  display: none;
}

.selectOuter {
  width: 100%;
  margin-left: auto;
  background-color: #1b1e31;
  padding: 8px 16px;
  border-width: 0;
  color: #d0d0da;
  border: solid 2px #303550;
  cursor: pointer;
  padding: 6px 14px;
  font-size: 16px;
  min-width: 0;
  box-shadow: none;
  text-align: left;
  font-weight: 300;
  line-height: 24px;
  user-select: none;
  border-radius: 8px;
  text-transform: none;
}

.selectInner {
  display: flex;
  min-width: 0;
  align-items: center;
  color: #d0d0da;
  cursor: pointer;
  text-align: left;
  font-weight: 300;
  line-height: 24px;
  user-select: none;
  text-transform: none;
}

.selectSort {
  color: #8a8ea8;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 4px;
  width: 25%;
}

.selectorSort {
  color: #8a8ea8;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 4px;
  width: 75%;
  background-color: #1b1e31;
  border: none;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8a8ea8;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #8a8ea8;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #8a8ea8;
}

@media only screen and (max-width: 1000px) {
  .poolGroupHeader {
    grid-template-columns: minmax(0, 50fr) minmax(0, 50fr);
  }

  .poolHeaderOther {
    display: none;
  }

  .poolHeaderDropdown {
    display: block;
  }
}

@media only screen and (max-width: 650px) {
  .poolGroupHeader {
    grid-template-columns: 1fr;
  }
}
</style>
