<script setup>
import { useStore } from "../store";
import ConnectWallet from "./ConnectWallet.vue";
import Connected from "./Connected.vue";
import { storeToRefs } from "pinia";
import { formatBalance } from "../util/helpers";

const store = useStore();
const { dogepup } = storeToRefs(store);
</script>
<template>
  <nav class="navbar navbar-expand-lg navbar-dark topBg">
    <!-- Container wrapper -->
    <div class="container">
      <!-- Toggle button -->
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <font-awesome-icon icon="fa-solid fa-bars" />
      </button>

      <!-- Collapsible wrapper -->
      <div class="collapse navbar-collapse" id="navbarNav">
        <!-- Navbar brand -->
        <router-link class="navbar-brand mt-2 mt-lg-0" to="/">
          <img
            src="@/assets/dogepup_dark.svg"
            height="40"
            alt="DogePup"
            loading="lazy"
          />
        </router-link>
        <!-- Left links -->
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link" to="/">Vaults</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/chart">Chart</router-link>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Bridge
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <a
                  class="dropdown-item"
                  target="_blank"
                  href="https://medium.com/@DogechainFamily/how-to-bridge-and-reclaim-doge-on-dogechain-377c35602e56"
                  >Bridge Medium Post</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  target="_blank"
                  href="https://bridge.dogechain.dog/bridge"
                  >Dogechain Bridge</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  target="_blank"
                  href="https://app.multichain.org/#/router"
                  >Multichain Bridge</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  target="_blank"
                  href="https://synapseprotocol.com/?inputCurrency=WETH+&outputCurrency=ETH&outputChain=42161"
                  >Synapse Protocol</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Social
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <a
                  class="dropdown-item"
                  target="_blank"
                  href="https://coinmarketcap.com/currencies/doge-pup-token/"
                  >Coinmarketcap</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  target="_blank"
                  href="https://www.coingecko.com/en/coins/doge-pup-token"
                  >Coingecko</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  target="_blank"
                  href="https://defillama.com/protocol/dogepup"
                  >Defi Llama</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  target="_blank"
                  href="https://twitter.com/PolyPup1"
                  >Twitter</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  target="_blank"
                  href="https://t.me/PolyPupFarm"
                  >Telegram</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  target="_blank"
                  href="https://discord.gg/5y6CF87jfC"
                  >Discord</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a
              href="https://paladinsec.co/projects/dogepup/"
              target="_blank"
              class="nav-link"
              >Audit</a
            >
          </li>
        </ul>
        <!-- Left links -->
      </div>
      <!-- Collapsible wrapper -->

      <!-- Right elements -->
      <div class="d-flex align-items-center">
        <div class="tokenPrice">
          <div class="d-flex align-items-center tokenPriceOuter">
            <div class="tokenImage">
              <img
                src="@/assets/tokens/dogepup.png"
                height="20px"
                alt="DogePup"
                loading="lazy"
              />
            </div>
            <div class="tokenPriceHeader">
              <a
                href="https://www.darkswap.finance/swap?outputCurrency=0x1b15b9446b9f632a78396a1680DAaE17f74Ce8d9"
                target="_blank"
                class="header-link"
                >${{ formatBalance(dogepup, 2) }}</a
              >
            </div>
          </div>
        </div>
        <div class="connectBtn">
          <Connected v-if="store.account" />
          <ConnectWallet v-else />
        </div>
      </div>
      <!-- Right elements -->
    </div>
    <!-- Container wrapper -->
  </nav>
</template>
<style>
.header-link {
  text-decoration: none;
  color: #fff;
}
.header-link:hover {
  text-decoration: underline;
  color: #fff;
}
.tokenPriceOuter {
  margin-right: 20px;
}
.tokenImage img {
  height: 23px;
  display: flex;
}

.tokenPriceHeader {
  font-size: 0.825rem;
  margin-left: 7px;
  font-weight: 300;
}
.nav-link {
  font-size: 0.975rem !important;
}
.main_price {
  font-size: 12px;
  margin-top: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 0.7rem;
  padding: 0.3 rem 0.5 rem;
}
.main_price span {
  background-color: rgba(119, 131, 143, 0.2);
  padding: 0.3rem 0.3rem;
  border-radius: 5px;
}
.second_token {
  margin-left: 10px;
}
.dropbtn {
  background-color: #8247e5;
  color: #fff;
  padding: 7px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  margin-top: 1px;
  margin-left: auto;
}
.bg-dark {
  background-color: #08080b;
}

ul.navbar-nav {
  margin-left: 12%;
}
.dropdown {
  position: relative;
  float: right;
}
.dropdown-content {
  overflow: hidden;
  display: none;
  position: absolute;
  background-color: #999;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px;
}

.dropdown-menu {
  background-color: #aeaeae;
  font-size: 0.85rem;
}

.dropdown-menu li:hover {
  background-color: #999;
  color: #000;
}

.dropdown-menu li .router-link-active {
  background-color: #e6e6e6;
}
.dropdown-content a:hover {
  background-color: rgb(123, 123, 123);
}

.dark .form-select {
  background: #2f3d75;
}

.prices {
  padding-right: 35px;
  padding-left: 5px;
  font-size: 14px;
}
#formParent {
  display: inline-block;
  position: relative;
}
header {
  display: block;
  margin-bottom: 0;
  background-color: #ffffff;
}

.connectBtn {
  font-weight: 300;
  display: flex;
  padding: 0px;
  background-color: #2f3d75;
  border-radius: 12px 10px 12px 10px;
  color: #efefef;
}

.walletSpan {
  border-radius: 10px;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #d7dfff;
  color: #080a1c;
  margin: auto 0px auto 0px;
  font-size: 0.8rem;
}

.chainIcon {
  height: 20px;
  margin-top: -3px;
}

.personSpan {
  display: block;
  margin: auto 0px;
  padding: 0px 12px;
  font-size: 0.8rem;
  cursor: pointer;
}

.wrongSpan {
  border-radius: 10px;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #ffc6c6;
  color: #080a1c;
  margin: auto 0px auto 0px;
  font-size: 0.7rem;
}

ul.navbar-nav span.badge {
  margin-top: 8px;
}
.settings {
  padding-left: 10px;
  padding-top: 1px;
  color: #333;
}
.dark .settings {
  color: #efefef;
}

.header_logo {
  height: 25px;
}

li.nav-item {
  margin-right: 7px;
}

li.nav-item:hover,
.router-link-active {
  color: #fff !important;
  font-weight: 700 !important;
}

.navbar-brand {
  border-bottom: none;
}

.navbar-brand img {
  height: 40px;
}

.light li.nav-item:hover,
.light .router-link-active {
  color: #efefef;
}

@media only screen and (max-width: 700px) {
  .w-lg-auto {
    display: none;
  }

  .navbar-brand {
    display: none;
  }

  .navbar-toggler-icon {
    color: #fff;
  }

  li.nav-item {
    margin-bottom: 7px;
  }
}
</style>
