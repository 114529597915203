<script setup>
import { ref } from "vue";
import { useStore } from "../store";

const store = useStore();
const open = ref(false);

async function connectWallet(network) {
  await store.connectWallet(network);
  open.value = false;
}
</script>
<template>
  <button class="walletSpan connectWallet" @click="open = true">
    Connect Wallet
  </button>
  <span class="personSpan"
    ><font-awesome-icon icon="fa-solid fa-wallet"
  /></span>

  <div class="modalConnect" v-if="open">
    <div class="modal-dialogs">
      <div class="modal-content">
        <div class="modal-body">
          <div class="flex flex-col">
            <h3 class="connectAWallet">
              Connect a Wallet<span
                class="closeConnectWallet"
                @click="open = false"
                >X
              </span>
            </h3>
            <div @click="connectWallet('metamask')" class="walletConnectButton">
              <span class="text-white">Metamask </span>
              <img
                src="@/assets/metamask.png"
                alt="MetaMask Logo"
                height="auto"
                width="25"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.dark .modal-content {
  background-color: #1b2443;
}

.dark .btn-close {
  color: #fff;
}

.card-foot {
  padding-right: 25px;
}

.dark .approval {
  background: #2f3d75;
}

.connectAWallet {
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
  margin-bottom: 1rem;
}

.closeConnectWallet {
  float: right;
  font-size: 1rem;
  font-weight: 300;
  cursor: pointer;
}

.walletConnectButton {
  background: #2f3d75;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.modalConnect {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background: hsla(0, 0%, 20%, 0.7);
}

/* using :target */
.modalConnect:target {
  display: flex;
}

.modal-dialogs {
  width: 350px;
  padding: 1rem 1rem;
  border-radius: 0.8rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;

  color: white;
  background-color: #1b2443;
  box-shadow: 0.4rem 0.4rem 2.4rem 0.2rem hsla(236, 50%, 50%, 0.3);
  position: relative;

  overflow: hidden;
}

.toppad {
  margin-top: 10px;
}
</style>
